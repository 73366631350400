import React, { useState, useContext, useEffect } from "react";
import { Card, CardContent, Typography } from "@mui/joy";
import { Trash2 } from "lucide-react";
import { deleteTown } from "../../apis/request";
import useRequest from "../../apis/useRequest";
import { api } from "../../convex/_generated/api";
import { useMutation, useQuery } from "convex/react";
import { PlatformContext } from "../../contexts/PlatformContext";

import {
  Modal,
  Chip,
  ModalDialog,
  ModalClose,
  DialogTitle,
  Button,
  Typography as JoyTypography,
} from "@mui/joy";
import { Id } from "../../convex/_generated/dataModel";

export interface TownCardProps {
  townName: string;
  townId: string;
  onClick?: () => void;
  refresh: () => void;
}
function TownCard({ townName, townId, onClick, refresh }: TownCardProps) {
  const makeRequest = useRequest();
  const [openDialog, setOpenDialog] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error("Context not found");
  }
  const { username } = context;
  const archive = useMutation(api.testing.archiveWorld);
  const castTownId: Id<"worlds"> = townId as Id<"worlds">;
  const handleDelete = () => {
    setOpenDialog(true);
  };
  const handleConfirmDelete = async () => {
    const response = await makeRequest<void, void>(
      deleteTown(username, townId)
    );
    await archive({ worldId: castTownId });
    setOpenDialog(false);
    refresh();
  };

  const result = useQuery(api.world.getWorldStatus, {
    worldId: castTownId,
  });

  const worldStatus = result?.worldStatus;
  return (
    <>
      <Card className="!bg-neutral-800 !border-1 !border-neutral-700 !border-solid !rounded-xl flex mr-1 p-4 h-[8rem] w-[12rem] !text-orange-500">
        <CardContent className="flex flex-col justify-between items-center">
          <Trash2
            style={{
              ...styles.trashIcon,
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
            color="red"
            onClick={handleDelete}
          />

          <h3 style={{ margin: "auto", fontFamily: "var(--font_b)" }}>
            {townName}
          </h3>
          {worldStatus?.status !== "inactive" &&
          worldStatus?.status !== "stoppedByDeveloper" ? (
            <Chip
              size="sm"
              color="danger"
              variant="outlined"
              style={{ position: "absolute", top: "10px", left: "10px" }}
            >
              Live
            </Chip>
          ) : (
            <Chip
              size="sm"
              color="primary"
              variant="soft"
              style={{ position: "absolute", top: "10px", left: "10px" }}
            >
              Paused
            </Chip>
          )}
          <Button
            onClick={onClick}
            variant="outlined"
            size="sm"
            color="warning"
            className="!border-orange-500  !font-bold !font-main  !rounded-full transition-all duration-200 hover:!bg-orange-500 hover:!text-white"
          >
            Enter
          </Button>
        </CardContent>
      </Card>
      <Modal open={openDialog} onClose={() => setOpenDialog(false)}>
        <ModalDialog className="!flex !flex-col !bg-neutral-800 !border-neutral-700 !border-solid !border-1 !rounded-xl ">
          <ModalClose />
          <DialogTitle className="!text-white">
            Delete the spot "{townName}" ?
          </DialogTitle>
          <JoyTypography className="!text-white">
            This cannot be undone
          </JoyTypography>
          <div className="flex justify-center gap-2">
            <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
            <Button onClick={handleConfirmDelete} color="danger">
              Delete
            </Button>
          </div>
        </ModalDialog>
      </Modal>
    </>
  );
}
const styles: { [key: string]: React.CSSProperties } = {
  trashIcon: {
    marginLeft: "auto",
    cursor: "pointer",
  },
};
export default TownCard;
