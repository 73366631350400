import { useState, FC, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import { useAuth0 } from "@auth0/auth0-react";
import { PlatformContext } from "../contexts/PlatformContext";
import { CircularProgress } from "@mui/material";
import { Character, Chat } from "../types";
import FeaturedSwiper from "./HomepageComponents/FeaturedSwiper";
import Explore from "./HomepageComponents/Explore";
import { Button } from "@mui/joy";
import NewsCarousel from "./HomepageComponents/NewsCarousel";
import SearchBar from "./HomepageComponents/SearchBar";
import Welcome from "./HomepageComponents/Welcome";
export interface CharacterGroup {
  character: Chat;
  chats: Chat[];
}

const HomePage: FC = () => {
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "useContext must be used within a CharactersContext.Provider"
    );
  }
  const { featuredLists } = context;
  const isMobile = window.innerWidth < 768;
  const isDynamicTheme = false;

  const [activeTab, setActiveTab] = useState<string>("featured");
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();

  const handleCharacterSelect = (character: Character) => {
    if (!isAuthenticated) {
      loginWithRedirect({
        appState: {
          returnTo: `/chat/${character.character_id}`,
        },
      });
    }
    navigate(`/chat/${character.character_id}`);
  };

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    document.title = "4Wall AI | Chat with your favorite characters!";
  }, []);

  if (isLoading) {
    return <CircularProgress />;
  }
  const headerAreaDesktop = (
    <>
      {!isAuthenticated && (
        <div className="w-full h-12 flex justify-between ">
          <div className="flex  items-center  space-x-2">
            <h2
              onClick={() => navigate("/")}
              className="cursor-pointer font-main text-[var(--primary-text-color)]  hover:text-fourwall-orange items-center"
            >
              4WALL AI{" "}
            </h2>
            <Button
              className="!bg-white !text-black !rounded-full !px-5 !py-2  !font-main"
              onClick={() => loginWithRedirect()}
            >
              Login
            </Button>
            <Button
              className="!bg-fourwall-orange text-white !rounded-full !p-2 !px-5 !font-main "
              onClick={() =>
                loginWithRedirect({
                  authorizationParams: {
                    screen_hint: "signup",
                  },
                })
              }
            >
              Sign Up
            </Button>
          </div>
          {!isMobile && <SearchBar />}
        </div>
      )}
      {isAuthenticated && (
        <div className="w-full h-10 flex justify-end m-1 items-end ">
          <SearchBar />
        </div>
      )}
    </>
  );
  const headerAreaMobile = (
    <>
      <div className="w-full h-10 flex justify-between">
        <h2
          onClick={() => navigate("/")}
          className="cursor-pointer font-main text-white  hover:text-fourwall-orange my-auto"
        >
          {isAuthenticated ? "" : "4WALL AI"}
        </h2>
        {isAuthenticated ? (
          <SearchBar />
        ) : (
          <div className="flex items-center space-x-2">
            <Button
              className="!bg-white !text-black !rounded-full !px-5 !py-2  !font-main"
              onClick={() => loginWithRedirect()}
            >
              Login
            </Button>
            <Button
              className="!bg-fourwall-orange text-white !rounded-full !p-2 !px-5 !font-main "
              onClick={() =>
                loginWithRedirect({
                  authorizationParams: {
                    screen_hint: "signup",
                  },
                })
              }
            >
              Sign Up
            </Button>
          </div>
        )}
      </div>
    </>
  );
  const mobileContainer = (
    <>
      <FeaturedSwiper
        featuredCharacters={Object.values(featuredLists)
          .flat()
          .sort(() => Math.random() - 0.5)}
        name="FEATURED"
        loaded={true}
        handleCharacterSelect={handleCharacterSelect}
      />
      <NewsCarousel />
      <Explore
        handleCharacterSelect={handleCharacterSelect}
        isDynamicTheme={isDynamicTheme}
      />
    </>
  );
  const desktopContainer = (
    <>
      <NewsCarousel />
      <div className="flex font-main text-white">
        <h2>CHARACTERS</h2>

        <div className="flex space-x-2 justify-between items-center m-4">
          <Button
            onClick={() => handleTabChange("featured")}
            className={`custom-tab-button ${
              activeTab === "featured" ? "active" : "inactive"
            }`}
          >
            Featured 🔥
          </Button>
          <Button
            onClick={() => handleTabChange("explore")}
            className={`custom-tab-button ${
              activeTab === "explore" ? "active" : "inactive"
            }`}
          >
            Explore 🌐
          </Button>
        </div>
      </div>

      {activeTab === "featured" &&
        Object.entries(featuredLists).map(([key, value]) => (
          <FeaturedSwiper
            key={key}
            featuredCharacters={value.sort(() => Math.random() - 0.5)}
            name={
              key === "moviesTV"
                ? "MOVIES & TV"
                : key === "isekaiRPG"
                  ? "ISEKAI"
                  : key.toUpperCase()
            }
            loaded={value.length > 0}
            handleCharacterSelect={handleCharacterSelect}
          />
        ))}
      {activeTab === "explore" && (
        <Explore
          handleCharacterSelect={handleCharacterSelect}
          isDynamicTheme={isDynamicTheme}
        />
      )}
    </>
  );
  if (!isAuthenticated) {
    return <Welcome />;
  }
  return (
    <div>
      <div className="flex flex-col">
        <div className="flex flex-col p-2 overflow-x-hidden">
          {isMobile ? headerAreaMobile : headerAreaDesktop}
          <div className={`${isMobile ? "p-0" : "p-8"}`}>
            {/* {isMobile ? mobileContainer : desktopContainer} */}
            {mobileContainer}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
