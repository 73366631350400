import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import { Id } from "../../convex/_generated/dataModel";
import { SelectElement } from "./Player";
import { Messages } from "./Messages";
import { toastOnError } from "../../toasts";
import { useSendInput } from "../../hooks/sendInput";
import { MessageSquareText } from "lucide-react";
import { GameId } from "../../convex/aiTown/ids";
import { Modal, ModalDialog, ModalClose, Button } from "@mui/joy";
import MemoryButton from "./common/MemoryButton";
import { ServerGame } from "../../hooks/serverGame";
import { StyledAvatar } from "./common/styledAvatar";
import { formatDistanceToNow } from "date-fns";
import { PlatformContext } from "../../contexts/PlatformContext";
import { Divider } from "@mui/joy";

export default function PlayerDetails({
  worldId,
  engineId,
  game,
  playerId,
  setSelectedElement,
  scrollViewRef,
}: {
  worldId: Id<"worlds">;
  engineId: Id<"engines">;
  game: ServerGame;
  playerId?: GameId<"players">;
  setSelectedElement: SelectElement;
  scrollViewRef: React.RefObject<HTMLDivElement>;
}) {
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error("PlatformContext is not defined");
  }
  const { username } = context;
  const humanTokenIdentifier = useQuery(api.world.userStatus, { worldId });
  const [memoryView, setMemoryView] = useState<boolean>();
  const players = [...game.world.players.values()];
  const humanPlayer = players.find((p) => p.human === humanTokenIdentifier);
  const humanConversation = humanPlayer
    ? game.world.playerConversation(humanPlayer)
    : undefined;
  // Always select the other player if we're in a conversation with them.

  const memories = useQuery(api.agent.memory.getMemories, {
    worldId,
    playerId: playerId ?? "",
  });

  if (humanPlayer && humanConversation) {
    const otherPlayerIds = [...humanConversation.participants.keys()].filter(
      (p) => p !== humanPlayer.id
    );
    playerId = otherPlayerIds[0];
  }

  const player = playerId && game.world.players.get(playerId);
  const playerConversation = player && game.world.playerConversation(player);

  const agents = game.world.agents;
  const agent = Array.from(agents.values()).find(
    (a) => a.playerId === playerId
  );

  const plan = agent && game.agentDescriptions.get(agent.id)?.plan;

  const previousConversation = useQuery(
    api.world.previousConversation,
    playerId ? { worldId, playerId } : "skip"
  );

  const playerDescription = playerId && game.playerDescriptions.get(playerId);

  const startConversation = useSendInput(engineId, "startConversation");
  const acceptInvite = useSendInput(engineId, "acceptInvite");
  const rejectInvite = useSendInput(engineId, "rejectInvite");
  const leaveConversation = useSendInput(engineId, "leaveConversation");

  useEffect(() => {
    if (!playerConversation && !previousConversation) {
      setMemoryView(true);
    }
  }, [playerConversation, previousConversation]);

  if (!playerId) {
    return (
      <div
        className="h-full text-xl flex text-center items-center p-4"
        style={{
          color: "var(--primary-text-color)",
          fontFamily: "var(--font_b)",
          fontSize: "1rem",
        }}
      >
        Click on an a character on the map to see chat history.
      </div>
    );
  }
  if (!player) {
    return null;
  }
  const isMe = humanPlayer && player.id === humanPlayer.id;
  const canInvite =
    !isMe && !playerConversation && humanPlayer && !humanConversation;
  const sameConversation =
    !isMe &&
    humanPlayer &&
    humanConversation &&
    playerConversation &&
    humanConversation.id === playerConversation.id;
  console.log(
    "DEBUGGERR playerConversation, humanConversation",
    playerConversation,
    humanConversation
  );
  const humanStatus =
    humanPlayer &&
    humanConversation &&
    humanConversation.participants.get(humanPlayer.id)?.status;
  const playerStatus =
    playerConversation && playerConversation.participants.get(playerId)?.status;

  const haveInvite = sameConversation && humanStatus?.kind === "invited";
  const waitingForAccept =
    sameConversation &&
    playerConversation.participants.get(playerId)?.status.kind === "invited";
  const waitingForNearby =
    sameConversation &&
    playerStatus?.kind === "walkingOver" &&
    humanStatus?.kind === "walkingOver";

  const inConversationWithMe =
    sameConversation &&
    playerStatus?.kind === "participating" &&
    humanStatus?.kind === "participating";

  const onStartConversation = async () => {
    if (!humanPlayer || !playerId) {
      return;
    }
    console.log(`Starting conversation`);
    await toastOnError(
      startConversation({ playerId: humanPlayer.id, invitee: playerId })
    );
  };
  const onAcceptInvite = async () => {
    if (!humanPlayer || !humanConversation || !playerId) {
      return;
    }
    await toastOnError(
      acceptInvite({
        playerId: humanPlayer.id,
        conversationId: humanConversation.id,
      })
    );
  };
  const onRejectInvite = async () => {
    if (!humanPlayer || !humanConversation) {
      return;
    }
    await toastOnError(
      rejectInvite({
        playerId: humanPlayer.id,
        conversationId: humanConversation.id,
      })
    );
  };
  const onLeaveConversation = async () => {
    if (!humanPlayer || !inConversationWithMe || !humanConversation) {
      return;
    }
    await toastOnError(
      leaveConversation({
        playerId: humanPlayer.id,
        conversationId: humanConversation.id,
      })
    );
    setSelectedElement(undefined);
  };

  // console.log("DEBUGGERR", !playerConversation, playerStatus);
  // const pendingSuffix = (inputName: string) =>
  //   [...inflightInputs.values()].find((i) => i.name === inputName) ? ' opacity-50' : '';

  const pendingSuffix = (s: string) => "";
  return (
    <>
      <Modal open={true} style={{ backdropFilter: "blur(0px)" }}>
        <ModalDialog className="!bg-neutral-900/90 !rounded-xl !border-neutral-800 !text-white w-[60%] !p-0 !ml-40 ">
          {/* HEADER */}
          <div className="flex flex-row  items-center justify-between !bg-neutral-900 p-4 rounded-t-xl font-main">
            <div className="flex flex-row ">
              <StyledAvatar
                size="large"
                src={
                  player?.human
                    ? `${process.env.REACT_APP_ASSETS_BUCKET}users/${username}/display_picture.jpg`
                    : `${process.env.REACT_APP_ASSETS_BUCKET}${playerDescription?.character_id}/display_picture.jpg`
                }
                alt={playerDescription?.name || ""}
              />
              <div className="flex flex-col mx-3 justify-center">
                <h3 className="m-0">{playerDescription?.name}</h3>
                {/* <p className="m-0 text-sm">{playerDescription?.bio}</p> */}
              </div>
            </div>
            {!playerConversation && previousConversation && (
              <p className="text-xs text-orange-500 italic">
                Showing previous conversation.
              </p>
            )}
            {!memoryView ? (
              <MemoryButton onClick={() => setMemoryView(!memoryView)} />
            ) : (
              <MessageSquareText
                onClick={() => setMemoryView(!memoryView)}
                className="w-8 h-8 cursor-pointer hover:!bg-fourwall-orange !rounded-full !p-2"
              />
            )}
            {inConversationWithMe && (
              <Button
                onClick={onLeaveConversation}
                className="!bg-clay-700 !rounded-full"
              >
                Leave Conversation
              </Button>
            )}
            {canInvite && (
              <Button
                onClick={onStartConversation}
                className="!bg-clay-700 !rounded-full !m-2"
              >
                Start Conversation
              </Button>
            )}

            <ModalClose onClick={() => setSelectedElement(undefined)} />
          </div>

          {!memoryView ? (
            <>
              {/* {plan && (
                <div className="w-full bg-neutral-900 m-0">
                  <p className="text-sm">{plan}</p>
                </div>
              )} */}

              {canInvite && (
                <a
                  className={
                    "mt-6 text-white shadow-solid text-xl cursor-pointer pointer-events-auto" +
                    pendingSuffix("startConversation")
                  }
                  onClick={onStartConversation}
                >
                  <div className="bg-clay-700 text-center mx-4 rounded-lg">
                    <span>Start conversation</span>
                  </div>
                </a>
              )}
              {/* {waitingForAccept && (
                <a className="mt-6 text-white shadow-solid text-xl cursor-pointer pointer-events-auto opacity-50">
              <div className="mx-4 bg-clay-700 text-center rounded-lg">
                <span>Waiting for accept...</span>
              </div>
            </a>
            )} */}
              {/* {waitingForNearby && (
            <a className="mt-6  text-white shadow-solid text-xl cursor-pointer pointer-events-auto opacity-70">
              <div className="mx-10 bg-clay-700 text-center rounded-lg">
                <span>Walking over...</span>
              </div>
            </a>
          )} */}
              {inConversationWithMe && (
                <a
                  className={
                    "mt-6 text-white shadow-solid text-xl cursor-pointer pointer-events-auto" +
                    pendingSuffix("leaveConversation")
                  }
                  onClick={onLeaveConversation}
                >
                  <div className="bg-clay-700 text-center mx-4 rounded-lg">
                    <span>Leave conversation</span>
                  </div>
                </a>
              )}
              {haveInvite && (
                <>
                  <a
                    className={
                      "mt-6 text-white shadow-solid text-xl cursor-pointer pointer-events-auto"
                    }
                    onClick={onAcceptInvite}
                  >
                    <div className="bg-clay-500 mx-6 text-center rounded-lg">
                      <span>Accept</span>
                    </div>
                  </a>
                  <a
                    className={
                      "mt-6 text-white shadow-solid text-xl cursor-pointer pointer-events-auto" +
                      pendingSuffix("rejectInvite")
                    }
                    onClick={onRejectInvite}
                  >
                    <div className="bg-clay-700 mx-6 text-center rounded-lg">
                      <span>Reject</span>
                    </div>
                  </a>
                </>
              )}
              {/* {!playerConversation &&
                player.activity &&
                player.activity.until > Date.now() && (
                  <div className="box flex-grow mt-6 bg-brown-900">
                    <h2
                      className=" text-base sm:text-lg text-center"
                      style={{
                        fontFamily: "var(--font_b)",
                        fontSize: "0.8rem",
                        textAlign: "center",
                        fontStyle: "italic",
                        color: "var(--primary-text-color)",
                      }}
                    >
                      {player.activity.description}
                    </h2>
                  </div>
                )} */}
              {/* <div className="desc">
            <p className="leading-tight -m-4 bg-transparent text-white sm:text-sm p-2">
              {isMe && <i>This is you!</i>}

              {!isMe && inConversationWithMe && (
                <>
                  <br />
                  <i>Conversing with you!</i>
                </>
              )}
            </p>
          </div> */}
              {/* {!playerConversation && previousConversation && (
            <h2
              className="bg-transparent text-lg text-white rounded-lg"
              style={{
                fontFamily: "var(--font_b)",
                fontSize: "1rem",
                textAlign: "center",
                margin: "auto",
                color: "var(--orange-brand-accent)",
              }}
            >
              Previous conversation
            </h2>
          )} */}
              <div className="h-[79.2vh] overflow-y-auto rounded-2xl bg-purple-900/30;">
                {!isMe &&
                  playerConversation &&
                  playerStatus?.kind === "participating" && (
                    <Messages
                      focusedPlayer={player}
                      worldId={worldId}
                      engineId={engineId}
                      inConversationWithMe={inConversationWithMe ?? false}
                      conversation={{ kind: "active", doc: playerConversation }}
                      humanPlayer={humanPlayer}
                      scrollViewRef={scrollViewRef}
                    />
                  )}
                {!playerConversation && previousConversation && (
                  <>
                    <Messages
                      focusedPlayer={player}
                      worldId={worldId}
                      engineId={engineId}
                      inConversationWithMe={false}
                      conversation={{
                        kind: "archived",
                        doc: previousConversation,
                      }}
                      humanPlayer={humanPlayer}
                      scrollViewRef={scrollViewRef}
                    />
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col max-h-[79.2vh] overflow-y-auto p-4">
                <h2 className="text-xl font-semibold mb-4">Memories</h2>
                {Object.entries(
                  (memories || []).reduce(
                    (acc, memory) => {
                      const timeAgo = formatDistanceToNow(
                        new Date(memory._creationTime),
                        { addSuffix: true }
                      );
                      if (!acc[timeAgo]) {
                        acc[timeAgo] = [];
                      }
                      acc[timeAgo].push(memory);
                      return acc;
                    },
                    {} as Record<string, typeof memories>
                  )
                )
                  .reverse()
                  .map(([timeAgo, memoriesGroup]) => (
                    <div key={timeAgo} className="mb-6">
                      <h3 className="text-lg font-medium mb-2">
                        {timeAgo.charAt(0).toUpperCase() + timeAgo.slice(1)}
                      </h3>
                      <Divider className="!bg-neutral-300" />
                      {memoriesGroup?.reverse().map((memory) => (
                        <p
                          key={memory._id}
                          className="text-sm text-gray-300 mb-2 mx-2"
                        >
                          {memory.description}
                        </p>
                      ))}
                    </div>
                  ))}
              </div>
            </>
          )}
        </ModalDialog>
      </Modal>
    </>
  );
}

const styles = {
  charImg: {
    width: "5em",
    height: "5rem",
    borderRadius: "50%",
    margin: "1rem",
  },
};
