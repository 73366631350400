import React, { useEffect, useRef, useContext, useState } from "react";
import useRequest from "../../apis/useRequest";
import { debounce } from "../Misc/SearchOverlay";
import { Character, SelectedCharacterProps } from "../../types";
import { Id } from "../../convex/_generated/dataModel";
import {
  Modal,
  ModalDialog,
  ModalClose,
  DialogContent,
  Button,
  Input,
  List,
  ListItem,
  Typography,
  ListItemDecorator,
  ListItemContent,
  Box,
  CircularProgress,
  IconButton,
} from "@mui/joy";
import { Search, RefreshCcw } from "lucide-react";
import { useMutation } from "convex/react";
import { api } from "../../convex/_generated/api";
import {
  characterSearch,
  auxLLM,
  getTownCharacterData,
  updateTown,
} from "../../apis/request";
import { StyledAvatar } from "./common/styledAvatar";
import { PlatformContext } from "../../contexts/PlatformContext";
import { TownContext } from "../../contexts/TownContext";
import { ACTIVITIES } from "../../convex/constants";
import EmojiPicker from "emoji-picker-react";
import { buildCharacterData } from "../TownPage/TownPage";
interface AddMemberProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedCharacters: SelectedCharacterProps[];
  setSelectedCharacters: (characters: SelectedCharacterProps[]) => void;
}
function AddMember({
  open,
  setOpen,
  selectedCharacters,
  setSelectedCharacters,
}: AddMemberProps) {
  const defaultPlan =
    "You are in 4Wall Spot, a digital town where you have the ability to walk around and interact with users and other characters.";
  const makeRequest = useRequest();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<Character[]>([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [selectedToAdd, setSelectedToAdd] =
    useState<SelectedCharacterProps | null>(null);
  const [planGenerating, setPlanGenerating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const context = useContext(PlatformContext);
  const townContext = useContext(TownContext);
  const [currentActivityIndex, setCurrentActivityIndex] = useState<
    number | null
  >(null);
  if (!context || !townContext) {
    throw new Error("Context not found");
  }
  const { NSFW, featuredLists, username } = context;
  const { townName, charactersMetadata, setCharactersMetadata, town_id } =
    townContext;
  const addCharacters = useMutation(api.world.addCharacters);
  const handleClickOutside = (event: MouseEvent) => {
    const emojiPicker = document.getElementById("emoji-picker");
    if (emojiPicker && !emojiPicker.contains(event.target as Node)) {
      setShowEmojiPicker(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleAddCharacter = async () => {
    console.log("add character");
    const characterId = selectedToAdd?.character_id;
    const characterData = await makeRequest<any, any>(
      getTownCharacterData(characterId as string)
    );

    for (const character of characterData) {
      if (!character.plan) {
        character.plan = selectedToAdd?.plan || defaultPlan;
      }
      if (!character.activities || character.activities.length === 0) {
        character.activities = selectedToAdd?.activities || [];
      }
    }
    const townCharacterData = buildCharacterData(characterData);
    const awsCharacterData = [
      ...charactersMetadata,
      {
        name: townCharacterData[0].name,
        bio: townCharacterData[0].bio,
        character_id: townCharacterData[0].character_id,
        description: townCharacterData[0].description,
        image_url: `${process.env.REACT_APP_ASSETS_BUCKET}${townCharacterData[0].character_id}/display_picture.jpg`,
        plan: townCharacterData[0].plan,
        activities: townCharacterData[0].activities,
      },
    ];

    try {
      await addCharacters({
        characters: townCharacterData,
        worldId: town_id as Id<"worlds">,
      });
      const response = await makeRequest<any, any>(
        updateTown(username, town_id, "characters"),
        { updatedCharacters: awsCharacterData }
      );
      setCharactersMetadata(awsCharacterData);
    } catch (error) {
      console.error(error);
    }
    setSelectedToAdd(null);
    setOpen(false);
  };
  const generateAIPlan = async (
    character: SelectedCharacterProps
  ): Promise<string> => {
    console.log("Generating Plan");
    // setIsPlanGeneratingId(character.character_id);
    setPlanGenerating(true);
    const systemPrompt =
      "You are a helpful assistant that generates a plan for a character based on the given context. \
      The plan should be a short, concise, and relevant objective for the character to complete. \
      The character is an agent in a virtual world. Only generate a one or two sentence plan for the character. \
      The plan should be relevant to the character description and something that the character would want to do.\
      Always create a plan even if context is not provided.\
      ";
    const userPrompt = `Generate a plan for ${character.name} based on the following context: ${character.description}.`;
    const response = await makeRequest<any, any>(auxLLM(), {
      messages: [
        { role: "system", content: systemPrompt },
        { role: "user", content: userPrompt },
      ],
    });
    console.log("Openai response", response);

    if (!response.content) {
      throw new Error("Error generating plan");
    }
    const plan = response.content || "";
    setPlanGenerating(false);
    return plan;
  };
  function parseSearchResults(searchResults: any) {
    let filteredHits = searchResults;
    if (!NSFW) {
      for (const hit of filteredHits) {
        if (hit.tags.includes("NSFW")) {
          filteredHits = filteredHits.filter((h: any) => h !== hit);
        }
      }
    }
    const characters = filteredHits.map((hit: any) => {
      const fields = hit;

      return {
        bio: fields.bio,
        character_id: fields.character_id,
        creator: fields.creator,
        name: fields.name,
        image_url: `${process.env.REACT_APP_ASSETS_BUCKET}${fields.character_id}/display_picture.jpg`,
        chat_background_url: `${process.env.REACT_APP_ASSETS_BUCKET}${fields.character_id}/chat_background.jpg`,
        interactions: fields.num_interactions,
        tags: fields.tags,
      };
    });

    return characters;
  }

  const handleActivityChange = (index: number, field: any, value: string) => {
    if (selectedToAdd) {
      const newActivities = [...selectedToAdd.activities];
      newActivities[index] = { ...newActivities[index], [field]: value };
      setSelectedToAdd({ ...selectedToAdd, activities: newActivities });
    }
  };

  const handleEmojiClick = (emojiObject: any, index: number) => {
    handleActivityChange(index, "emoji", emojiObject.emoji);
    setShowEmojiPicker(false);
  };
  const fetchSearchResults = async (value: string) => {
    setIsLoading(true);
    try {
      const response = await makeRequest<any, any>(characterSearch(), {
        query: value,
      });
      const newCharacters = parseSearchResults(response.results);
      setSearchResults(newCharacters);
      setIsLoading(false);
    } catch (error) {
      console.error("Search error:", error);
      setIsLoading(false);
    }
  };

  const debouncedSearch = useRef(debounce(fetchSearchResults, 300)).current;

  useEffect(() => {
    if (searchTerm) {
      debouncedSearch(searchTerm);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (selectedToAdd) {
      const generatePlan = async () => {
        const plan = await generateAIPlan(selectedToAdd);
        setSelectedToAdd((prevCharacter) =>
          prevCharacter &&
          prevCharacter.character_id === selectedToAdd.character_id
            ? { ...prevCharacter, plan }
            : prevCharacter
        );
      };
      generatePlan();
    }
  }, [selectedToAdd?.character_id]);

  const displayList = searchTerm.trim()
    ? searchResults
    : [
        ...featuredLists.anime,
        ...featuredLists.isekaiRPG,
        ...featuredLists.gaming,
        ...featuredLists.moviesTV,
      ];
  return (
    <Modal open={open}>
      <ModalDialog className="!bg-neutral-800 !border-neutral-700 !rounded-lg !transition-all !duration-300">
        <ModalClose
          onClick={() => {
            setSelectedToAdd(null);
            setOpen(false);
          }}
        />
        <DialogContent className="!flex !flex-row !m-2">
          <div className="w-80 h-full min-h-full mr-4 text-fourwall-orange border-r border-white/20">
            <Input
              startDecorator={<Search />}
              id="searchCharacters"
              placeholder="Search Characters"
              type="search"
              fullWidth
              variant="outlined"
              value={searchTerm}
              // style={styles.searchInput}
              color="warning"
              className="!bg-neutral-900 !text-white !rounded-full !border-none !w-4/5 !mx-auto !my-2"
              onChange={(e) => setSearchTerm(e.target.value)}
              autoFocus={false}
            />
            {!searchTerm.trim() && (
              <h3 className="text-orange-500 text-center">
                Featured Characters
              </h3>
            )}
            <Box
              sx={{
                height: 400,
                overflow: "auto",
                "&::-webkit-scrollbar": {
                  width: "5px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "var(--orange-brand-accent)",
                  borderRadius: "4px",
                },
              }}
              className="!text-white !border-none !bg-neutral-900 p-2 rounded-lg"
            >
              <List
                sx={{
                  "--ListItemDecorator-size": "56px",

                  overflowY: "auto",
                }}
              >
                {isLoading ? (
                  <CircularProgress size="md" />
                ) : (
                  displayList.map((character) => (
                    <ListItem
                      className="cursor-pointer !rounded-full m-1 "
                      key={character.character_id}
                      onClick={() => {
                        setSelectedToAdd({
                          ...character,
                          plan: defaultPlan,
                          activities: ACTIVITIES,
                          description: character.description || "",
                        });
                      }}
                      sx={{
                        backgroundColor: selectedCharacters.some(
                          (selectedCharacter) =>
                            selectedCharacter.character_id ===
                            character.character_id
                        )
                          ? "rgba(255, 255, 255, 0.1)"
                          : "transparent",
                      }}
                    >
                      <ListItemDecorator>
                        <StyledAvatar
                          alt={character.name}
                          size="small"
                          src={`${process.env.REACT_APP_ASSETS_BUCKET}${character.character_id}/display_picture.jpg`}
                        />
                      </ListItemDecorator>
                      <ListItemContent className="!text-white">
                        <p>{character.name}</p>
                      </ListItemContent>
                    </ListItem>
                  ))
                )}
              </List>
            </Box>
          </div>
          <div className="w-[40rem] ml-4 items-center flex flex-col">
            {selectedToAdd ? (
              <>
                <div className="flex items-center gap-2">
                  <StyledAvatar
                    src={`${process.env.REACT_APP_ASSETS_BUCKET}${selectedToAdd.character_id}/display_picture.jpg`}
                    alt={selectedToAdd.name || ""}
                  />
                  <h1 className="text-center">{selectedToAdd.name}</h1>
                </div>
                <div className="flex flex-col items-center !font-main !p-2">
                  <div className="bg-white rounded-lg p-2 mb-4">
                    <div className="h-32 w-32 bg-gray-200 rounded-lg">
                      SPACE FOR SPRITE
                    </div>
                  </div>
                  <div className="w-full justify-center text-white ">
                    <h2 className="text-lg font-semibold mb-2">Plan</h2>
                    <div className="flex items-center w-full gap-2">
                      <div className="flex items-center gap-2 w-full">
                        {planGenerating ? (
                          <div className="flex items-center gap-2">
                            <Typography className="!text-orange-100 !font-main !italic">
                              Generating Plan...
                            </Typography>
                            <CircularProgress size="sm" color="warning" />
                          </div>
                        ) : (
                          <>
                            <Input
                              value={selectedToAdd?.plan}
                              //   onChange={}
                              color="warning"
                              className="!bg-black !text-sm !border-none !text-white !rounded-lg !py-2 !px-4 !w-full"
                            />
                            <RefreshCcw
                              size={16}
                              color="var(--primary-text-color)"
                              style={{ cursor: "pointer" }}
                              onClick={async () => {
                                const plan =
                                  await generateAIPlan(selectedToAdd);
                                setSelectedToAdd((prevCharacter) =>
                                  prevCharacter
                                    ? { ...prevCharacter, plan }
                                    : null
                                );
                              }}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-full justify-center text-white">
                    <h2 className="text-lg font-semibold mb-2">Actions</h2>
                    <div className="grid grid-cols-3 gap-2 w-full">
                      {selectedToAdd?.activities.map((activity, index) => (
                        <div
                          key={index}
                          className="bg-gray-600 rounded p-1 flex items-center relative"
                        >
                          <IconButton
                            onClick={() => {
                              setCurrentActivityIndex(index);
                              setShowEmojiPicker(true);
                            }}
                            className="!text-2xl !m-1 hover:!bg-neutral-900"
                          >
                            {activity.emoji}
                          </IconButton>
                          <Input
                            value={activity.description}
                            color="warning"
                            onChange={(e) =>
                              handleActivityChange(
                                index,
                                "description",
                                e.target.value
                              )
                            }
                            className="!text-xs !text-center !bg-neutral-900 !border-none !text-white"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  {showEmojiPicker && (
                    <div id="emoji-picker" className="absolute z-10">
                      <EmojiPicker
                        onEmojiClick={(emojiObject) =>
                          handleEmojiClick(emojiObject, currentActivityIndex!)
                        }
                      />
                    </div>
                  )}
                  <Button
                    className="!mt-4 !bg-fourwall-orange !text-white !rounded-full !px-5 !py-2 hover:!bg-orange-500"
                    onClick={() => {
                      handleAddCharacter();
                    }}
                  >
                    ADD
                  </Button>
                </div>
              </>
            ) : (
              <div className="flex items-center justify-center h-full">
                <Typography className="!text-neutral-600 !font-main !font-bold !text-4xl">
                  Add members to your spot
                </Typography>
              </div>
            )}
          </div>
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
}

export default AddMember;
