import React, {
  useContext,
  useState,
  useRef,
  useEffect,
  CSSProperties,
} from "react";

import { debounce } from "../Misc/SearchOverlay";
import { X, EllipsisVertical } from "lucide-react";
import {
  Modal,
  ModalDialog,
  ModalClose,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Input,
  List,
  ListItem,
  Typography,
  ListItemDecorator,
  ListItemContent,
  Box,
  CircularProgress,
  FormControl,
  FormLabel,
  FormHelperText,
  Snackbar,
  Alert,
} from "@mui/joy";
import { PlatformContext } from "../../contexts/PlatformContext";
import { CircleHelp, Search } from "lucide-react";
import { Character, SelectedCharacterProps } from "../../types";
import { characterSearch } from "../../apis/request";
import useRequest from "../../apis/useRequest";
import MapCarousel from "./MapCarousel";
import AddOrEditInGameCharacter from "./AddOrEditInGameCharacter";
import { ACTIVITIES } from "../../convex/constants";
import { StyledAvatar } from "../Town/common/styledAvatar";
interface CreateTownDialogProps {
  dialogOpen: boolean;
  setDialogOpen: (open: boolean) => void;
  townName: string;
  setTownName: (name: string) => void;
  isPlanGeneratingId: string | null;
  selectedCharacters: SelectedCharacterProps[];
  handleCharacterChange: (character: SelectedCharacterProps) => void;
  handlePlanChange: (characterId: string, newPlan: string) => void;
  setSelectedCharacters: (characters: SelectedCharacterProps[]) => void;
  generateAIPlan: (character: SelectedCharacterProps) => Promise<string>;
  createGotoTown: (townName: string) => void;
}

const townMaps = [
  // { name: "A16Z AI Town", image: "/gentle-obj.png" },
  { name: "4WALL HQ", image: "/AlphaMap.png" },
  // { name: "Random Town", image: "/darksunset.png" },
  // { name: "Random Town 2", image: "/darksunset.png" },
  // { name: "Random Town 3", image: "/darksunset.png" },
];
const CreateTownDialog: React.FC<CreateTownDialogProps> = ({
  dialogOpen,
  setDialogOpen,
  townName,
  setTownName,
  isPlanGeneratingId,
  selectedCharacters,
  handleCharacterChange,
  handlePlanChange,
  setSelectedCharacters,
  generateAIPlan,
  createGotoTown,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<Character[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [helpOpen, setHelpOpen] = useState(false);
  const context = useContext(PlatformContext);
  const [addCharacterOptions, setAddCharacterOptions] = useState<{
    open: boolean;
    character: SelectedCharacterProps;
    editing: boolean;
  }>({
    open: false,
    character: {
      character_id: "",
      description: "",
      bio: "",
      plan: "",
      image_url: "",
      name: "",
      activities: ACTIVITIES,
    },
    editing: false,
  });
  const [selectedMap, setSelectedMap] = useState(townMaps[0]);
  if (!context) {
    throw new Error(
      "useContext must be used within a PlatformContext.Provider"
    );
  }
  const {
    username,
    featuredLists,
    NSFW,
    alertInfo,
    setAlertInfo,
    showAlert,
    closeAlert,
  } = context;
  const defaultPlan =
    "You are in 4Wall Spot, a digital town where you have the ability to walk around and interact with users and other characters.";
  const makeRequest = useRequest();

  function parseSearchResults(searchResults: any) {
    let filteredHits = searchResults;
    if (!NSFW) {
      for (const hit of filteredHits) {
        if (hit.tags.includes("NSFW")) {
          filteredHits = filteredHits.filter((h: any) => h !== hit);
        }
      }
    }
    const characters = filteredHits.map((hit: any) => {
      const fields = hit;

      return {
        bio: fields.bio,
        character_id: fields.character_id,
        creator: fields.creator,
        name: fields.name,
        image_url: `${process.env.REACT_APP_ASSETS_BUCKET}${fields.character_id}/display_picture.jpg`,
        chat_background_url: `${process.env.REACT_APP_ASSETS_BUCKET}${fields.character_id}/chat_background.jpg`,
        interactions: fields.num_interactions,
        tags: fields.tags,
      };
    });

    return characters;
  }

  const fetchSearchResults = async (value: string) => {
    setIsLoading(true);
    try {
      const response = await makeRequest<any, any>(characterSearch(), {
        query: value,
      });
      const newCharacters = parseSearchResults(response.results);
      setSearchResults(newCharacters);
      setIsLoading(false);
    } catch (error) {
      console.error("Search error:", error);
      setIsLoading(false);
    }
  };

  const debouncedSearch = useRef(debounce(fetchSearchResults, 300)).current;
  const removeCharacter = (characterId: string) => {
    setSelectedCharacters(
      selectedCharacters.filter(
        (character) => character.character_id !== characterId
      )
    );
  };
  useEffect(() => {
    if (searchTerm) {
      debouncedSearch(searchTerm);
    }
  }, [searchTerm]);
  const handleCreateTown = () => {
    if (!townName.trim()) {
      alert("Spot name cannot be empty");
      return;
    }
    if (selectedCharacters.length < 2) {
      alert("You must select at least 2 characters");
      return;
    }
    createGotoTown(townName);
  };

  const displayList = searchTerm.trim()
    ? searchResults
    : [
        ...featuredLists.anime,
        ...featuredLists.isekaiRPG,
        ...featuredLists.gaming,
        ...featuredLists.moviesTV,
      ];

  return (
    <>
      <Modal open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <ModalDialog className="!bg-neutral-800 !rounded-xl !border-neutral-700 !text-white">
          <ModalClose />
          <Modal open={helpOpen} onClose={() => setHelpOpen(false)}>
            <ModalDialog
              style={styles.helperDialog}
              className="!bg-neutral-900 !rounded-xl"
            >
              <X
                onClick={() => setHelpOpen(false)}
                style={{
                  position: "fixed",
                  top: "1rem",
                  right: "1rem",
                  cursor: "pointer",
                  color: "var(--orange-brand-accent)",
                }}
              />
              <DialogTitle>
                <h2 className="text-fourwall-orange">Create a New Spot</h2>
              </DialogTitle>
              <DialogContent>
                <div className="text-white">
                  <ul style={{ padding: "0", listStyleType: "none" }}>
                    <li style={{ padding: "10px 0" }}>
                      <strong>Name Your Spot:</strong> Enter a unique and fun
                      name for your Spot in the "Spot Name" field.
                    </li>

                    <li style={{ padding: "10px 0" }}>
                      <strong>Add Characters:</strong> Select up to 5 characters
                      for your Spot. Choose from the featured list or find your
                      favorites using the search bar.
                    </li>

                    <li style={{ padding: "10px 0" }}>
                      <strong>Provide a Plan:</strong> For each character, you
                      can provide an objective or goal for them in the Spot.
                    </li>

                    <li style={{ padding: "10px 0" }}>
                      <strong>Select a Map:</strong> Currently, we have one map
                      available, but more maps will be added soon. Eventually,
                      you'll also be able to create your own maps.
                    </li>

                    <li style={{ padding: "10px 0" }}>
                      <strong>Create Your Spot:</strong> Once you've named your
                      Spot, added characters, and selected a map, click "Create"
                      to bring your Spot to life!
                    </li>
                  </ul>
                </div>
              </DialogContent>
            </ModalDialog>
          </Modal>
          <DialogTitle
            level="h2"
            style={{
              fontFamily: "var(--font_b)",
            }}
          >
            Create a New Spot
            <CircleHelp
              color="white"
              size={32}
              onClick={() => setHelpOpen(true)}
              style={{ cursor: "pointer" }}
            />
          </DialogTitle>
          <DialogContent className="!flex !flex-row !m-2">
            <div className="w-80 h-full min-h-full mr-4 text-fourwall-orange border-r border-white/20">
              <Input
                startDecorator={<Search />}
                id="searchCharacters"
                placeholder="Search Characters"
                type="search"
                fullWidth
                variant="outlined"
                value={searchTerm}
                // style={styles.searchInput}
                color="warning"
                className="!bg-neutral-900 !text-white !rounded-full !border-none !w-4/5 !mx-auto !my-2"
                onChange={(e) => setSearchTerm(e.target.value)}
                autoFocus={false}
              />
              {!searchTerm.trim() && (
                <h3 className="text-orange-500 text-center">
                  Featured Characters
                </h3>
              )}
              <Box
                sx={{
                  height: 400,
                  overflow: "auto",
                  "&::-webkit-scrollbar": {
                    width: "5px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "transparent",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "var(--orange-brand-accent)",
                    borderRadius: "4px",
                  },
                }}
                className="!text-white !border-none !bg-neutral-900 p-2 rounded-lg"
              >
                <List
                  sx={{
                    "--ListItemDecorator-size": "56px",

                    overflowY: "auto",
                  }}
                >
                  {isLoading ? (
                    <CircularProgress size="md" />
                  ) : (
                    displayList.map((character) => (
                      <ListItem
                        className="cursor-pointer !rounded-full m-1 "
                        key={character.character_id}
                        onClick={() => {
                          setAddCharacterOptions({
                            open: true,
                            editing: false,
                            character: {
                              character_id: character.character_id,
                              description: character.description || "",
                              bio: character.bio,
                              plan: defaultPlan,
                              image_url: character.image_url,
                              name: character.name,
                              activities: ACTIVITIES,
                            },
                          });
                        }}
                        sx={{
                          backgroundColor: selectedCharacters.some(
                            (selectedCharacter) =>
                              selectedCharacter.character_id ===
                              character.character_id
                          )
                            ? "rgba(255, 255, 255, 0.1)"
                            : "transparent",
                        }}
                      >
                        <ListItemDecorator>
                          <StyledAvatar
                            alt={character.name}
                            size="small"
                            src={`${process.env.REACT_APP_ASSETS_BUCKET}${character.character_id}/display_picture.jpg`}
                          />
                        </ListItemDecorator>
                        <ListItemContent>
                          <p style={styles.sidebarText}>{character.name}</p>
                        </ListItemContent>
                      </ListItem>
                    ))
                  )}
                </List>
              </Box>
            </div>
            <div className="w-[40rem] ml-4 items-center flex flex-col">
              <FormControl className="!w-full">
                <FormLabel htmlFor="townName" className="!text-white">
                  Spot Name
                </FormLabel>
                <Input
                  autoFocus
                  id="townName"
                  placeholder="Enter your spot name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  color="warning"
                  className="!bg-neutral-900 !text-white !rounded-lg !border-none !w-5/6 !my-2"
                  value={townName}
                  style={{ ...styles.townNameInput, width: "100%" }}
                  onChange={(e) => setTownName(e.target.value.slice(0, 50))}
                />
                <FormHelperText className="!text-white">
                  {`${townName.length}/50 characters`}
                </FormHelperText>
              </FormControl>
              <Box className="flex flex-col gap-2 p-4">
                <Typography className="!text-orange-500 !font-main">
                  Your Spot Members
                </Typography>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 sm:gap-4 md:gap-5">
                  {selectedCharacters.map((character) => (
                    <div
                      key={character.character_id}
                      className="bg-neutral-700 rounded-lg p-2 relative items-center"
                      style={{ width: "100%", minHeight: "50px" }}
                    >
                      <div className="flex items-center gap-2">
                        <StyledAvatar
                          alt={character.name || ""}
                          src={`${process.env.REACT_APP_ASSETS_BUCKET}${character.character_id}/display_picture.jpg`}
                          size="small"
                        />
                        <Typography className="!text-white !text-xs sm:!text-sm flex-grow truncate">
                          {character.name}
                        </Typography>
                      </div>

                      <EllipsisVertical
                        size={14}
                        onClick={() => {
                          setAddCharacterOptions({
                            ...addCharacterOptions,
                            character: character,
                            open: true,
                            editing: true,
                          });
                        }}
                        className="!absolute !bottom-1 !right-1 !mb-1 !p-0 !text-white !cursor-pointer"
                      />

                      <X
                        size={14}
                        onClick={() => removeCharacter(character.character_id)}
                        className="!absolute !top-1 !right-1 !mb-1 !p-0 !text-white !cursor-pointer"
                      />
                    </div>
                  ))}
                  {/* {selectedCharacters.length < 5 && (
                    <div
                      className="bg-neutral-700 rounded-lg p-2 flex items-center justify-center cursor-pointer"
                      onClick={() => {
                        
                      }}
                    >
                      <Typography>+ Add Character</Typography>
                    </div>
                  )} */}
                </div>
                {/* <Table>
                  <thead>
                    <tr>
                      <th className="!text-orange-500 !w-[30%] !bg-transparent">
                        Name
                      </th>
                      <th className="!text-orange-500 !w-[70%] !bg-transparent">
                        Character Plan
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedCharacters.map((character, index) => (
                      <tr key={character.character_id}>
                        <td>
                          <Box className="flex items-center gap-2">
                            <X
                              size={16}
                              color="var(--primary-text-color)"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleCharacterChange({
                                  character_id: character.character_id,
                                  plan: defaultPlan,
                                  description: character.description,
                                  bio: character.bio,
                                  image_url: character.image_url,
                                  name: character.name,
                                  activities: character.activities,
                                })
                              }
                            />
                            <Avatar
                              src={`${process.env.REACT_APP_ASSETS_BUCKET}${character.character_id}/display_picture.jpg`}
                            />
                            <Typography
                              sx={{
                                fontFamily: "var(--font_b)",
                                color: "var(--primary-text-color)",
                              }}
                            >
                              {character.name}
                            </Typography>
                          </Box>
                        </td>
                        <td>
                          {isPlanGeneratingId === character.character_id ? (
                            <div className="flex items-center gap-2">
                              <Typography className="text-white !font-main !italic">
                                Generating Plan...
                              </Typography>
                              <CircularProgress size="sm" color="warning" />
                            </div>
                          ) : (
                            <div className="flex items-center gap-2">
                              <Input
                                type="text"
                                variant="soft"
                                placeholder="Plan"
                                sx={{
                                  backgroundColor: "black",
                                  color: "var(--primary-text-color)",
                                  width: "100%",
                                }}
                                value={character.plan}
                                onChange={(e) => {
                                  handlePlanChange(
                                    character.character_id,
                                    e.target.value
                                  );
                                }}
                              />
                              <RefreshCcw
                                size={16}
                                color="var(--primary-text-color)"
                                style={{ cursor: "pointer" }}
                                onClick={async () => {
                                  const newPlan =
                                    await generateAIPlan(character);
                                  console.log(newPlan);
                                  handlePlanChange(
                                    character.character_id,
                                    newPlan
                                  );
                                }}
                              />
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table> */}
              </Box>
              <div className="mt-auto w-full flex flex-col gap-2">
                <MapCarousel maps={townMaps} handleMapSelect={setSelectedMap} />
                <DialogActions className="ml-auto">
                  <Button
                    color="primary"
                    onClick={handleCreateTown}
                    className="!bg-fourwall-orange !rounded-lg"
                  >
                    CREATE
                  </Button>
                </DialogActions>
              </div>
            </div>
          </DialogContent>
          <Snackbar
            open={alertInfo.open}
            autoHideDuration={6000}
            onClose={closeAlert}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert color={alertInfo.severity}>{alertInfo.message}</Alert>
          </Snackbar>
        </ModalDialog>
      </Modal>
      <AddOrEditInGameCharacter
        open={addCharacterOptions.open}
        setOpen={(open) => {
          const maxCharacters = 5;
          const canAddCharacter = selectedCharacters.length < maxCharacters;

          if (open && !canAddCharacter) {
            alert(`You can only have ${maxCharacters} characters in your spot`);
          } else {
            setAddCharacterOptions({ ...addCharacterOptions, open });
          }
        }}
        character={addCharacterOptions.character}
        onAddCharacter={handleCharacterChange}
        editing={addCharacterOptions.editing}
      />
    </>
  );
};
const styles: { [key: string]: CSSProperties } = {
  stuffContainer: {
    display: "flex",
    flexDirection: "row",
    color: "var(--orange-brand-accent)",
  },
  leftSide: {
    width: "25rem",
    height: "100%",
    minHeight: "100%",
    marginRight: "1rem",
    color: "var(--orange-brand-accent)",
    borderRight: "1px solid rgba(255, 255, 255, 0.2)",
  },
  rightSide: {
    width: "40rem",
    marginLeft: "1rem",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },

  townNameInput: {
    width: "20rem",
    backgroundColor: "var(--primary-background-color)",
    color: "var(--primary-text-color)",
  },
  searchInput: {
    width: "20rem",

    backgroundColor: "var(--primary-background-color)",
    color: "var(--primary-text-color)",
  },
  sidebarText: {
    fontSize: "0.9rem",
    color: "var(--primary-text-color)",
    fontFamily: "var(--font_b)",
  },

  mapSection: {
    marginTop: "auto",
    width: "100%",
  },
  displayCharacterContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "1rem",
  },
  helperDialog: {
    backgroundColor: "var(--blue-brand-accent)",
    fontFamily: "var(--font_b)",
    border: "none",
    width: "50%",
  },
};

export default CreateTownDialog;
