import React, { useState, useContext } from "react";

import {
  Pencil,
  Trash,
  BotMessageSquare,
  MessageSquare,
  MessageSquareShare,
  Lock,
} from "lucide-react";
import {
  Modal,
  ModalDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { CreatedCharactersProps } from "../ProfilePage/CreatedCharacters";
import { Character, UserAssets } from "../../types";
import { deleteCharacter } from "../../apis/request";
import useRequest from "../../apis/useRequest";
import { PlatformContext } from "../../contexts/PlatformContext";
import "./Character.css";

export interface CharacterProfileProps {
  character_id: string;
  name: string;
  interactions: number;
  num_saves: number;
  isOther?: boolean;
  image_url: string;
  is_public: boolean;
  setCharacters: React.Dispatch<React.SetStateAction<CreatedCharactersProps[]>>;
  setUserAssets: React.Dispatch<React.SetStateAction<UserAssets>>;
}

export const CharacterProfile: React.FC<CharacterProfileProps> = ({
  character_id,
  name,
  interactions,
  num_saves,
  isOther,
  is_public,
  image_url,
  setCharacters,
  setUserAssets,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const makeRequest = useRequest();
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error("useContext must be used within a PlatformContext");
  }
  const username = context.username;
  const navigate = useNavigate();
  const handleDelete = async () => {
    console.log("Deleting character with ID:", character_id);
    const deleteChat = async () => {
      const request_body = {
        item: "character",
        character_id: character_id,
      };

      try {
        const response = await makeRequest(
          deleteCharacter(username, character_id)
        );
        console.log("Response:", response);
      } catch (error) {
        console.error("Error deleting character:", error);
      }
    };
    setCharacters((prev) => {
      return prev.filter(
        (character) => character.character_id !== character_id
      );
    });
    setUserAssets((prev) => {
      return {
        ...prev,
        characters: prev.characters.filter(
          (character) => character.character_id !== character_id
        ),
      };
    });
    deleteChat();
    setOpen(false);
  };
  return (
    <>
      <div className="overflow-hidden fastFadeInUp flex flex-col items-center max-w-[11rem] ">
        <div className="h-[16rem] flex flex-col p-1  space-y-2 bg-neutral-900/90 border-solid border-neutral-800 text-white rounded-xl">
          <div className="relative w-full aspect-square">
            <img
              src={`${process.env.REACT_APP_ASSETS_BUCKET}${character_id}/display_picture.jpg?${React.useMemo(() => Date.now(), [])}`}
              alt={name}
              onError={(e) => {
                const target = e.target as HTMLImageElement;
                target.onerror = null;
                target.src =
                  "https://4thwall-assets.s3.amazonaws.com/default_assets/display_picture.jpg";
              }}
              className="rounded-lg w-full h-full object-cover"
            />
            <div
              className="absolute bottom-0 left-0 right-0 h-1/3 rounded-lg  flex items-end justify-center p-2 z-10"
              style={{
                background:
                  "linear-gradient(to top, rgba(0,0,0,0.8), transparent)",
              }}
            >
              <h3 className="text-sm font-main text-white">{name}</h3>
            </div>
          </div>
          {String(is_public).toLowerCase() === "false" && (
            <div className="absolute top-2 right-2 bg-neutral-900 rounded-full p-1">
              <Lock size={16} />
            </div>
          )}
          <p className="p-0 text-sm flex flex-row items-center m-auto gap-2">
            <BotMessageSquare
              strokeWidth={1.5}
              className="text-fourwall-orange"
            />
            {interactions}
          </p>

          <div className="flex flex-col h-full justify-end">
            <div className="flex flex-row gap-4 mx-auto mb-2">
              <MessageSquareShare
                strokeWidth={1.5}
                size={20}
                cursor="pointer"
                onClick={() => navigate(`/chat/${character_id}`)}
              />
              {!isOther && (
                <>
                  {" "}
                  <Pencil
                    strokeWidth={1.5}
                    size={20}
                    cursor="pointer"
                    onClick={() => navigate(`/create/${character_id}`)}
                  />
                  <Trash
                    strokeWidth={1.5}
                    size={20}
                    color="red"
                    cursor="pointer"
                    onClick={handleClickOpen}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal open={open} onClose={handleClose}>
        <ModalDialog className="!bg-neutral-900 !text-white !border-neutral-700">
          <DialogTitle>{"Confirm Delete"}</DialogTitle>
          <DialogContent>
            <p className="text-sm text-white">
              Are you sure you want to delete this character?
            </p>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleDelete} color="danger">
                Delete
              </Button>
            </DialogActions>
          </DialogContent>
        </ModalDialog>
      </Modal>
    </>
  );
};
