import React from "react";
import { useMutation, useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import { Pause, Play } from "lucide-react";
// import Button from "./buttons/Button";
import Button from "@mui/joy/Button";

export default function FreezeButton({ worldStatus }: any) {
  const stopAllowed = useQuery(api.testing.stopAllowed) ?? false;
  const defaultWorld = worldStatus;
  const frozen =
    defaultWorld?.status === "stoppedByDeveloper" ||
    defaultWorld?.status === "inactive";
  const unfreeze = useMutation(api.testing.resume);
  const freeze = useMutation(api.testing.stop);

  const flipSwitch = async () => {
    if (frozen) {
      console.log("Unfreezing");
      await unfreeze({
        worldId: defaultWorld.worldId,
      });
    } else {
      console.log("Freezing");
      await freeze({
        worldId: defaultWorld.worldId,
      });
    }
  };

  return !stopAllowed ? null : (
    <>
      {frozen ? (
        <Play onClick={flipSwitch} className="cursor-pointer" size={32} />
      ) : (
        <Pause onClick={flipSwitch} className="cursor-pointer" size={32} />
      )}
    </>
  );
}

const styles = {
  button: {
    // backgroundColor: "var(--orange-brand-accent)",
    fontFamily: "var(--font_b)",
    color: "white",
    borderRadius: "10px",
  },
  svg: {
    width: "20px",
    height: "20px",
    marginRight: "10px",
  },
};
