import React, { useState, useContext, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { List, ListDivider, Divider, ListSubheader, Box } from "@mui/joy";
import { useAuth0 } from "@auth0/auth0-react";
import { PlatformContext } from "../../contexts/PlatformContext";
import { TownContext } from "../../contexts/TownContext";
import { Home, ChevronsLeft, SquarePlus, VolumeX } from "lucide-react";
import { Id } from "../../convex/_generated/dataModel";
import SpotMemberDisplay from "./SpotMemberDisplay";
import AddMember from "./AddMember";
import FreezeButton from "./FreezeButton";
import { useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";

export default function GodviewSidebar({
  worldId,
  engineId,
  open,
  setOpen,
  game,
  setSelectedElement,
  onPlayerClick,
}: any) {
  const context = useContext(PlatformContext);
  const townContext = useContext(TownContext);
  if (!context || !townContext) {
    throw new Error(
      "useContext must be used within a PlatformContext.Provider and TownContext.Provider"
    );
  }
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const { isMobile, username } = context;
  const { town_id, townName } = townContext;
  const memoizedTimestamp = useMemo(() => Date.now(), []);
  const players = [...(game?.world.players.values() || [])];

  const castTownId: Id<"worlds"> = town_id as Id<"worlds">;
  const [addMemberOpen, setAddMemberOpen] = useState(false);
  const result = useQuery(api.world.getWorldStatus, {
    worldId: castTownId,
  });
  const worldStatus = result?.worldStatus;
  const handleHomeClick = () => {
    setSelectedElement(null);
    navigate("/town");
  };

  const handleAddMember = () => {
    const nonHumanPlayers = players.filter((player) => !player.human);
    if (nonHumanPlayers.length >= 5) {
      alert(
        "Cannot add more members. Maximum limit of 5 non-human characters reached."
      );
    } else {
      setAddMemberOpen(true);
    }
  };
  return (
    <Box
      className={`transition-all duration-300 ease-in-out ${!open ? "!bg-neutral-900" : "!py-4 !px-2 !bg-neutral-900"}`}
      sx={{
        width: open ? "22rem" : "0rem",
        height: "100svh",
        color: "white",
        display: "flex",
        flex: "1 1 auto",
        opacity: open ? 1 : 0,
        boxSizing: "border-box",
        flexDirection: "column",
        borderRight: open ? "1px solid rgba(255, 255, 255, 0.2)" : "none",
        transition: "all 0.3s ease-in-out",
      }}
    >
      <Box className="flex items-center justify-between transition-all duration-300 h-[10%]">
        <ListSubheader className="!text-xl !text-white !font-main">
          {townName}
        </ListSubheader>
        <div className="flex justify-end gap-2">
          <ChevronsLeft onClick={() => setOpen(false)} />
        </div>
      </Box>
      <ListDivider />
      <div className="flex justify-between items-center">
        <p className="!text-lg !text-white !font-main !font-bold">Members</p>
        <SquarePlus
          fill="white"
          className="cursor-pointer !text-black"
          onClick={handleAddMember}
        />
      </div>
      <List>
        {players
          .filter((player) => !player.human)
          .map((player) => (
            <SpotMemberDisplay
              key={player.id}
              worldId={worldId}
              engineId={engineId}
              playerId={player.id}
              game={game}
              setSelectedElement={setSelectedElement}
              onPlayerClick={onPlayerClick}
            />
          ))}
      </List>
      <Divider />
      <Box className="mt-auto flex flex-row justify-between items-center">
        <List>
          {/* <ListItem className="justify-between !text-white ">
            <div
              className="flex items-center hover:bg-neutral-800 rounded-lg p-2 cursor-pointer"
              // onClick={handleUserClick}
            > */}
          <SpotMemberDisplay
            user={true}
            worldId={worldId}
            engineId={engineId}
            playerId={players.find((p) => p.human)?.id}
            game={game}
            setSelectedElement={setSelectedElement}
            onPlayerClick={onPlayerClick}
          />
          {/* <StyledAvatar
                size="small"
                src={`${process.env.REACT_APP_ASSETS_BUCKET}users/${username}/display_picture.jpg?${memoizedTimestamp}`}
                alt={username}
              />
              <Typography
                level="title-md"
                className="!text-white !m-2 !font-main"
              >
                {username}
              </Typography> */}
          {/* </div> */}
        </List>
        <div className="flex items-center gap-2 text-gray-400">
          <FreezeButton worldStatus={worldStatus} size="small" />
          <VolumeX className="cursor-pointer " size={32} />
          <Home
            onClick={handleHomeClick}
            className="cursor-pointer"
            size={30}
          />
        </div>
        {/* </ListItem> */}
      </Box>
      <AddMember
        open={addMemberOpen}
        setOpen={setAddMemberOpen}
        selectedCharacters={players}
        setSelectedCharacters={() => {}}
      />
    </Box>
  );
}
