import { useContext, useState } from "react";
import { PlatformContext } from "../../contexts/PlatformContext";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListDivider,
  ListSubheader,
  Typography,
  Divider,
  Drawer,
} from "@mui/joy";
import {
  History,
  ChevronsRight,
  Earth,
  ListRestart,
  Save,
  Edit,
} from "lucide-react";
import PersonaPicker from "./PersonaPicker";
import ScenarioEditor from "./ScenarioEditor";
import { SquareUserRound, Settings } from "lucide-react";
import { ChatContext } from "../../contexts/ChatContext";
import { updateScenario } from "../../apis/request";
import useRequest from "../../apis/useRequest";
import { useNavigate } from "react-router-dom";
import { Alert, Snackbar } from "@mui/joy";
function SettingsSidebar({
  isOpen,
  setIsOpen,
  handleSave,
  handleDelete,
  userIsCreator,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  handleSave: () => void;
  handleDelete: () => void;
  userIsCreator: boolean;
}) {
  const navigate = useNavigate();
  const isMobile = window.innerWidth < 768;
  const context = useContext(PlatformContext);
  const chatContext = useContext(ChatContext);
  if (!context || !chatContext) {
    throw new Error(
      "useContext must be used within a PlatformContext.Provider and ChatContext.Provider"
    );
  }

  const { username, userAssets, showAlert } = context;
  const {
    chatId,
    character_id,
    activePersonaId,
    setActivePersonaId,
    activePersona,
    setActivePersona,
  } = chatContext;

  const makeRequest = useRequest();
  const [personaPickerOpen, setPersonaPickerOpen] = useState(false);
  const [scenarioEditorOpen, setScenarioEditorOpen] = useState(false);
  const [historyOpen, setHistoryOpen] = useState(false);
  const handleUpdateScenario = async (scenario: string) => {
    if (chatId === "") {
      console.log("No chat id will only update scenario once chat is sent");
    }
    try {
      const response = await makeRequest(
        updateScenario(username, chatId, character_id),
        {
          scenario: scenario,
        }
      );
      showAlert("Scenario updated", "success");
    } catch (error) {
      console.error(error);
      showAlert("Error updating scenario", "danger");
    }
  };
  const handleChoosePersona = (persona_id: string) => {
    if (persona_id === activePersonaId) {
      setActivePersonaId("");
      setActivePersona({
        name: "",
        description: "",
        persona_id: "",
        default: false,
      });
      showAlert("Persona deactivated", "success");
    } else {
      setActivePersonaId(persona_id);
      setActivePersona(
        userAssets.personas.find(
          (persona) => persona.persona_id === persona_id
        ) || {
          name: "",
          description: "",
          persona_id: "",
          default: false,
        }
      );
      showAlert(
        "Active persona changed to " +
          userAssets.personas.find(
            (persona) => persona.persona_id === persona_id
          )?.name,
        "success"
      );
    }
  };
  return (
    <>
      <Box
        sx={{
          width: isOpen
            ? isMobile
              ? "100%"
              : "18rem"
            : isMobile
              ? "0"
              : "2.3rem",
          height: "100vh",
          color: "white",
          fontFamily: "var(--font_b)",
          position: isMobile ? "absolute" : "fixed",
          right: 0,

          zIndex: isMobile ? "1000" : "0",
          borderLeft: !isMobile ? "1px solid rgba(255, 255, 255, 0.2)" : "none",
          bgcolor: isMobile
            ? isOpen
              ? "rgba(0,0,0,0.9)"
              : "transparent"
            : "rgba(0,0,0,0.9)",
          transition: "width 0.3s ease",
        }}
      >
        {!isOpen && (
          <Box className="flex">
            {!isMobile && (
              <Settings
                className="mt-4 ml-2 cursor-pointer"
                onClick={() => setIsOpen(!isOpen)}
              />
            )}
          </Box>
        )}
        {isOpen && (
          <>
            <Box className="flex flex-row items-center justify-center font-main ">
              <ChevronsRight
                className="cursor-pointer ml-0"
                style={{
                  position: "absolute",
                  left: "10",
                }}
                size={28}
                onClick={() => setIsOpen(!isOpen)}
              />
              <h1 className="text-2xl">Settings</h1>
            </Box>
            <List className="!my-2 !mx-4">
              <ListItem className="cursor-pointer hover:bg-neutral-800   !rounded-lg  ">
                <Save size={20} className="text-orange-500" />
                <Typography
                  onClick={handleSave}
                  className="!font-main !text-orange-100 !text-sm"
                >
                  Save Chat
                </Typography>
              </ListItem>
              {userIsCreator && (
                <ListItem
                  className="cursor-pointer hover:bg-neutral-800 !rounded-lg "
                  onClick={() => navigate(`/create/${character_id}`)}
                >
                  <Edit size={20} className="text-orange-500" />
                  <Typography className="!font-main !text-orange-100 !text-sm">
                    Edit Character
                  </Typography>
                </ListItem>
              )}
              <ListItem
                className="cursor-pointer hover:bg-neutral-800 !rounded-lg "
                onClick={handleDelete}
              >
                <ListRestart size={20} className="text-orange-500" />
                <Typography className="!font-main !text-orange-100 !text-sm">
                  Delete and Start New
                </Typography>
              </ListItem>
              <ListItem
                className="cursor-pointer hover:bg-neutral-800 !rounded-lg "
                onClick={() => setHistoryOpen(true)}
              >
                <History size={20} className="text-orange-500" />

                <Typography className="!font-main !text-orange-100 !text-sm">
                  History
                </Typography>
              </ListItem>
            </List>
            <ListDivider className="!bg-neutral-700 !mx-4" />

            <List className="!mt-2 !mx-4">
              <ListSubheader className="!text-white !font-main !text-sm">
                Chat Options
              </ListSubheader>
              <ListItem
                className="cursor-pointer hover:bg-neutral-800 !rounded-lg "
                onClick={() => setPersonaPickerOpen(true)}
              >
                <SquareUserRound size={20} className="text-orange-500" />
                <Typography className="!font-main !text-orange-100 !text-sm">
                  Persona
                </Typography>
                <Typography className="!font-main !text-gray-400 !text-sm !ml-auto !align-end">
                  {activePersona.name}
                </Typography>
              </ListItem>
              <ListItem
                className="cursor-pointer hover:bg-neutral-800 !rounded-lg "
                onClick={() => setScenarioEditorOpen(true)}
              >
                <Earth size={20} className="text-orange-500" />
                <Typography className="!font-main !text-orange-100 !text-sm">
                  Scenario
                </Typography>
                {/* <Typography className="!font-main !text-gray-400 !text-sm !ml-auto !align-end">
                    {activePersona.name}
                </Typography> */}
              </ListItem>
            </List>
          </>
        )}
      </Box>
      {/* <Snackbar
        open={alertInfo.open}
        onClose={closeAlert}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        className=" !text-orange-500 !text-sm !font-main !items-center !justify-center !border-none !bg-transparent"
      >
        <Alert variant="soft" color={alertInfo.severity}>
          {alertInfo.message}
        </Alert>
      </Snackbar> */}
      <PersonaPicker
        open={personaPickerOpen}
        setOpen={setPersonaPickerOpen}
        onChoose={handleChoosePersona}
        activePersonaId={activePersonaId}
      />
      <ScenarioEditor
        open={scenarioEditorOpen}
        setOpen={setScenarioEditorOpen}
        onUpdateScenario={(scenario: string) => handleUpdateScenario(scenario)}
      />
      <Drawer
        anchor="right"
        size="sm"
        open={historyOpen}
        onClose={() => setHistoryOpen(false)}
      >
        <List className="!bg-neutral-900">
          {userAssets.chats
            .filter(
              (chat) =>
                chat.character_id === character_id && chat.chat_id !== chatId
            )
            .sort(
              (a, b) =>
                new Date(b.last_interaction).getTime() -
                new Date(a.last_interaction).getTime()
            )
            .map((chat) => {
              const lastInteractionDate = new Date(chat.last_interaction);
              const now = new Date();
              const diffInDays = Math.floor(
                (now.getTime() - lastInteractionDate.getTime()) /
                  (1000 * 3600 * 24)
              );
              let friendlyTime;
              if (diffInDays === 0) {
                friendlyTime = "Today";
              } else if (diffInDays === 1) {
                friendlyTime = "Yesterday";
              } else if (diffInDays < 7) {
                friendlyTime = `${diffInDays} days ago`;
              } else {
                friendlyTime = lastInteractionDate.toLocaleDateString();
              }
              return (
                <ListItem
                  className="cursor-pointer hover:bg-neutral-800 !rounded-lg !mx-2 "
                  key={chat.chat_id}
                >
                  <Typography className="!font-main !text-orange-100 !text-sm">
                    {friendlyTime}
                  </Typography>
                </ListItem>
              );
            })}
        </List>
      </Drawer>
    </>
  );
}
export default SettingsSidebar;
const styles = {
  divider: {
    width: "90%",
    margin: "auto",
    backgroundColor: "darkgray",
  },
};
