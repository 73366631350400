import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  forwardRef,
  useRef,
} from "react";
import { PlatformContext } from "../../contexts/PlatformContext";
import { Swiper as SwiperInstance } from "swiper/types"; // Importing the type for Swiper instance
import { Character } from "../../types";
import { Menu, MenuItem, Button } from "@mui/joy";

import ExploreGrid from "../common/ExploreGrid";
import "./HomepageComponents.css";
import { characterSearch } from "../../apis/request";
import useRequest from "../../apis/useRequest";
import { CircularProgress } from "@mui/material";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  FreeMode,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";

type SortOrder = "New" | "Most Popular" | "Random";

const tags: string[] = [
  "Fantasy",
  "Anime",
  "Celebrities",
  "Comedy",
  "Historical",
  "Horror",
  "Role Play",
  "Sci-Fi",
  "Superheroes",
  "Gaming",
  "LGBTQ+",
  "Original Character",
  "NSFW",
  "Movies & TV",
];

type SortButtonProps = {
  onSortChange: (newSortOrder: SortOrder) => void;
};

function SortButton({ onSortChange }: SortButtonProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [sortOrder, setSortOrder] = useState<SortOrder>("Random");

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (order: SortOrder) => {
    setSortOrder(order);
    onSortChange(order);
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="sort-menu"
        aria-haspopup="true"
        size="sm"
        onClick={handleClick}
        color="primary"
        className="!bg-orange-500"
      >
        SORT: {sortOrder}
      </Button>
      <Menu
        id="sort-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleClose(sortOrder)}
      >
        <MenuItem onClick={() => handleClose("New")}>New</MenuItem>
        <MenuItem onClick={() => handleClose("Most Popular")}>
          Most Popular
        </MenuItem>
        <MenuItem onClick={() => handleClose("Random")}>Random</MenuItem>
      </Menu>
    </div>
  );
}

interface TagsComponentProps {
  onTagsChange: (tags: string[]) => void;
  initialTags: string[];
  sortOrder: SortOrder;
}

type ArrowProps = React.HTMLAttributes<HTMLButtonElement>;

const CustomPrevArrow = forwardRef<HTMLButtonElement, ArrowProps>(
  (props, ref) => (
    <button
      ref={ref}
      className="absolute left-[10px] top-1 z-10 px-2 opacity-0 cursor-pointer bg-transparent border-none transition-opacity duration-300 hover:opacity-80"
      {...props}
    >
      {!isMobile && <ChevronLeft className="text-orange-500 fill-black" />}
    </button>
  )
);

const CustomNextArrow = forwardRef<HTMLButtonElement, ArrowProps>(
  (props, ref) => (
    <button
      ref={ref}
      className="absolute right-[10px] top-1 z-10 px-2 opacity-0 cursor-pointer bg-transparent border-none text-fourwall-orange transition-opacity duration-300 hover:opacity-80"
      {...props}
    >
      {!isMobile && <ChevronRight className="text-orange-500 fill-black" />}
    </button>
  )
);
const isMobile = window.innerWidth < 768;
CustomPrevArrow.displayName = "CustomPrevArrow";
CustomNextArrow.displayName = "CustomNextArrow";
const TagsComponent = ({
  onTagsChange,
  initialTags,
  sortOrder,
}: TagsComponentProps) => {
  const [selectedTags, setSelectedTags] = useState(["Anime"]);
  const [isEnd, setIsEnd] = useState(false);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isHovered, setIsHovered] = useState(false);

  const toggleTag = (tag: string) => {
    let updatedTags: string[] = [];
    if (selectedTags.includes(tag)) {
      if (selectedTags.length === 1) {
        alert("Atleast one tag must be selected!");
        return;
      }
      updatedTags = selectedTags.filter((t: string) => t !== tag);
    } else {
      updatedTags = [...selectedTags, tag];
    }
    console.log(updatedTags);
    setSelectedTags(updatedTags);
    onTagsChange(updatedTags);
  };
  useEffect(() => {
    onTagsChange(selectedTags);
  }, []);
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);

  return (
    <div
      className="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Swiper
        modules={[
          FreeMode,
          Mousewheel,
          Navigation,
          Pagination,
          Scrollbar,
          A11y,
        ]}
        touchEventsTarget="container"
        spaceBetween={4}
        slidesPerView={"auto"}
        touchRatio={1}
        speed={400}
        mousewheel={{
          forceToAxis: true,
          releaseOnEdges: true,
        }}
        freeMode={true}
        slidesPerGroup={3}
        onSlideChange={(swiper) => {
          setIsEnd(swiper.isEnd);
          setIsBeginning(swiper.isBeginning);
        }}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        onBeforeInit={(swiper: SwiperInstance) => {
          if (typeof swiper.params.navigation !== "boolean") {
            // Ensure it's not the boolean 'false'
            (swiper.params.navigation as any).prevEl = prevRef.current;
            (swiper.params.navigation as any).nextEl = nextRef.current;
          }
        }}
        className=""
      >
        <>
          <CustomPrevArrow
            ref={prevRef}
            style={{ opacity: isHovered ? 0.8 : 0 }}
          />
          <CustomNextArrow
            ref={nextRef}
            style={{ opacity: isHovered ? 0.8 : 0 }}
          />
        </>

        {tags.map((tag, index) => (
          <SwiperSlide key={index} className="w-auto">
            <Button
              size="md"
              className={`${selectedTags.includes(tag) ? "!bg-orange-500" : "!bg-gray-700"} !text-white !rounded-md`}
              onClick={() => toggleTag(tag)}
            >
              {tag}
            </Button>
          </SwiperSlide>
        ))}
      </Swiper>
      {!isEnd && (
        <div
          className="absolute top-0 right-0 bottom-0 w-12 pointer-events-none z-10"
          style={{
            background:
              "linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0.6))",
          }}
        ></div>
      )}
      {!isBeginning && (
        <div
          className="absolute top-0 left-0 bottom-0 w-12 pointer-events-none z-10"
          style={{
            background:
              "linear-gradient(to left, rgba(0,0,0,0), rgba(0,0,0,0.6))",
          }}
        ></div>
      )}
    </div>
  );
};

interface ExploreProps {
  handleCharacterSelect: (character: Character) => void;
  isDynamicTheme: boolean;
}

function Explore({ handleCharacterSelect, isDynamicTheme }: ExploreProps) {
  const makeRequest = useRequest();
  const [characters, setCharacters] = useState<Character[]>([]);
  const [sortOrder, setSortOrder] = useState<SortOrder>("Random");
  const [chosenTags, setChosenTags] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState<number>(60);
  const [startIndex, setStartIndex] = useState(0);

  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "useContext must be used within a CharactersContext.Provider"
    );
  }
  const { NSFW } = context;

  function parseSearchResults(searchResults: any) {
    let filteredHits = searchResults;
    if (!NSFW) {
      for (const hit of filteredHits) {
        if (hit.tags.includes("NSFW")) {
          filteredHits = filteredHits.filter((h: any) => h !== hit);
        }
      }
    }
    const characters = filteredHits.map((hit: any) => {
      const fields = hit;

      return {
        bio: fields.bio,
        character_id: fields.character_id,
        creator: fields.creator,
        name: fields.name,
        image_url: `${process.env.REACT_APP_ASSETS_BUCKET}${fields.character_id}/display_picture.jpg`,
        chat_background_url: `${process.env.REACT_APP_ASSETS_BUCKET}${fields.character_id}/chat_background.jpg`,
        interactions: fields.num_interactions,
        tags: fields.tags,
      };
    });

    return characters;
  }

  const handleTagSubmit = async (
    currentTags: string[],
    updatedSortOrder?: SortOrder
  ): Promise<void> => {
    setLoading(true);
    setChosenTags(currentTags);
    setStartIndex(0); // Reset start index when submitting new tags

    if (currentTags.length === 0) {
      setCharacters([]);
      setLoading(false);
      return;
    }
    setChosenTags(currentTags);
    console.log("startIndex", startIndex);

    try {
      const response = await makeRequest<any, any>(characterSearch(), {
        tags: currentTags.map((tag) => tag.split(" ")[0].toLowerCase()),
        sort_order: updatedSortOrder || sortOrder,
        query: "",
        size: itemsPerPage,
        start: 0,
        NSFW: NSFW,
      });

      const newCharacters = parseSearchResults(response.results);
      console.log("newCharacters", newCharacters);
      setCharacters(newCharacters);
    } catch (error) {
      console.error("Error fetching characters:", error);
      setCharacters([]);
    } finally {
      setLoading(false);
    }
  };

  const handleLoadMoreCharacters = async () => {
    setLoading(true);
    try {
      const response = await makeRequest<any, any>(characterSearch(), {
        tags: chosenTags.map((tag) => tag.split(" ")[0].toLowerCase()),
        sort_order: sortOrder,
        query: "",
        size: itemsPerPage,
        start: startIndex + itemsPerPage, // Use startIndex + itemsPerPage to avoid duplicates
        NSFW: NSFW,
      });
      const newCharacters = parseSearchResults(response.results);
      setCharacters((prevCharacters) => [...prevCharacters, ...newCharacters]);
      setStartIndex((prevStartIndex) => prevStartIndex + itemsPerPage);
    } catch (error) {
      console.error("Error loading more characters:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSortOrderChange = (newSortOrder: SortOrder) => {
    setSortOrder(newSortOrder);
    handleTagSubmit(chosenTags, newSortOrder);
  };
  const isMobile = window.innerWidth < 768;

  return (
    <div className="space-y-3">
      <div className="flex justify-between items-center">
        {isMobile && <h3 className="text-white font-main">EXPLORE</h3>}
        <SortButton
          onSortChange={(newSortOrder) => {
            handleSortOrderChange(newSortOrder);
          }}
        />
      </div>
      <TagsComponent
        initialTags={["Anime"]}
        onTagsChange={handleTagSubmit}
        sortOrder={sortOrder}
      />
      {loading && (
        <div
          style={{
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      )}
      <ExploreGrid
        characters={characters}
        onLoadMore={handleLoadMoreCharacters}
        handleCharacterSelect={handleCharacterSelect}
      />
    </div>
  );
}

export default Explore;
