import React, { useEffect, useState, useContext } from "react";
import TownCard from "./TownCard";
import { useMutation } from "convex/react";
import { api } from "../../convex/_generated/api";
import useRequest from "../../apis/useRequest";
import { Town } from "../../types";
import { useNavigate } from "react-router-dom";
import { CSSProperties } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { CircularProgress } from "@mui/material";
import { Plus } from "lucide-react";
import { SocialIcon } from "react-social-icons";
import {
  createTown,
  getTowns,
  getTownCharacterData,
  addToWaitlist,
  getIfUserInWaitlist,
  auxLLM,
} from "../../apis/request";

import { PlatformContext } from "../../contexts/PlatformContext";
import CreateTownDialog from "./CreateTownDialog";
import {
  Modal,
  ModalDialog,
  Card,
  CardContent,
  Button,
  Chip,
  ModalClose,
} from "@mui/joy";
import { ACTIVITIES } from "../../convex/constants";
import { X } from "lucide-react";
import { SelectedCharacterProps } from "../../types";
export const buildCharacterData = (characters: any) => {
  const characterIds = ["f1", "f2", "f3", "f4", "f5", "f6", "f7", "f8"];
  console.log("characters", characters);
  return characters.map((character: any, index: number) => {
    return {
      name: character.name,
      identity: character.description,
      bio: character.bio,
      plan: character.plan,
      image: `${process.env.REACT_APP_ASSETS_BUCKET}${character.character_id}/display_picture`,
      character: characterIds[index % characterIds.length],
      character_id: character.character_id,
      activities: character.activities,
    };
  });
};
function TownPage() {
  const [selectedWorldId, setSelectedWorldId] = useState<string | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [townName, setTownName] = useState("");
  const [isPlanGeneratingId, setIsPlanGeneratingId] = useState<string | null>(
    null
  );
  const [selectedCharacters, setSelectedCharacters] = useState<
    SelectedCharacterProps[]
  >([]);
  const [infoOpen, setInfoOpen] = useState(false);
  const [isWaitlistLoading, setIsWaitlistLoading] = useState(false);

  const [towns, setTowns] = useState<Town[]>([]);
  const [loadingTowns, setLoadingTowns] = useState(false);
  const [isInWaitlist, setIsInWaitlist] = useState(false);
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  const createNewWorld = useMutation(api.world.createNewWorld);
  const makeRequest = useRequest();
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "useContext must be used within a PlatformContext.Provider"
    );
  }
  const { username, isAlphaTester, socialUrls } = context;
  const fetchUserTowns = async () => {
    setLoadingTowns(true);
    const response = await makeRequest<Town[], void>(getTowns(username));
    setTowns(response);
    setLoadingTowns(false);
  };
  const checkIfUserInWaitlist = async () => {
    const response = await makeRequest<any, void>(
      getIfUserInWaitlist(username)
    );
    console.log("response", response);
    setIsInWaitlist(response.in_waitlist);
  };
  useEffect(() => {
    if (isAlphaTester) {
      fetchUserTowns();
    } else {
      checkIfUserInWaitlist();
    }
  }, [username]);
  if (isLoading || loadingTowns)
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress />
      </div>
    );
  if (!isAuthenticated) {
    loginWithRedirect({
      appState: { returnTo: "/town" },
    });
  }
  const createTownOnBackend = async (townBody: Town) => {
    const response = await makeRequest<void, Town>(
      createTown(username),
      townBody
    );
    console.log(response);
  };

  const createGotoTown = async (town_name: string) => {
    console.log("selectedCharacters", selectedCharacters);
    const characterData = await makeRequest<any, void>(
      getTownCharacterData(
        selectedCharacters.map((c) => c.character_id).join(",")
      )
    );
    for (const character of characterData) {
      if (!character.plan) {
        character.plan = selectedCharacters.find(
          (c) => c.character_id === character.character_id
        )?.plan;
      }
      if (!character.activities || character.activities.length === 0) {
        character.activities =
          selectedCharacters.find(
            (c) => c.character_id === character.character_id
          )?.activities || [];
      }
    }
    const townCharacterData = buildCharacterData(characterData);

    console.log("townCharacterData", townCharacterData);
    let worldStatus = await createNewWorld({
      characters: townCharacterData,
    });
    console.log("Created world in convex");
    console.log("selectedCharacters", selectedCharacters);
    createTownOnBackend({
      town_name: town_name,
      town_id: worldStatus.worldId,
      username: username,
      characters: selectedCharacters,
    });
    setSelectedWorldId(worldStatus.worldId);
    navigate(`/town/${worldStatus.worldId}`, {
      state: { townName: town_name, charactersMetadata: selectedCharacters },
    });
  };
  const generateAIPlan = async (
    character: SelectedCharacterProps
  ): Promise<string> => {
    console.log("Generating Plan");
    setIsPlanGeneratingId(character.character_id);
    const systemPrompt =
      "You are a helpful assistant that generates a plan for a character based on the given context. \
      The plan should be a short, concise, and relevant objective for the character to complete. \
      The character is an agent in a virtual world. Only generate a one or two sentence plan for the character. \
      The plan should be relevant to the character description and something that the character would want to do.\
      Always create a plan even if context is not provided.\
      ";
    const userPrompt = `Generate a plan for ${character.name} based on the following context: ${character.description}.`;
    const response = await makeRequest<any, any>(auxLLM(), {
      messages: [
        { role: "system", content: systemPrompt },
        { role: "user", content: userPrompt },
      ],
    });
    console.log("Openai response", response);

    if (!response.content) {
      throw new Error("Error generating plan");
    }
    const plan = response.content || "";
    setIsPlanGeneratingId(null);
    return plan;
  };
  const handleCharacterChange = async (character: SelectedCharacterProps) => {
    setSelectedCharacters((prev) => {
      const existingIndex = prev.findIndex(
        (ch) => ch.character_id === character.character_id
      );
      if (existingIndex !== -1) {
        // Update existing character
        const updatedCharacters = [...prev];
        updatedCharacters[existingIndex] = character;
        return updatedCharacters;
      } else if (prev.length < 5) {
        // Add new character
        return [...prev, character];
      } else {
        alert("Please choose at most 5 characters.");
        return prev;
      }
    });
    console.log("selectedCharacters", selectedCharacters);
    // const plan = await generateAIPlan(character);
    // handlePlanChange(character.character_id, plan);
  };

  const handlePlanChange = (characterId: string, newPlan: string) => {
    setSelectedCharacters((prevCharacters) =>
      prevCharacters.map((char) =>
        char.character_id === characterId ? { ...char, plan: newPlan } : char
      )
    );
  };
  const handleJoinWaitlist = async () => {
    setIsWaitlistLoading(true);
    try {
      const response = await makeRequest(addToWaitlist(username));
      setIsInWaitlist(true);
    } catch (error) {
      console.error("Error joining waitlist:", error);
    }

    setIsWaitlistLoading(false);
  };

  if (!isAlphaTester) {
    return (
      <div
        className="flex flex-col items-center justify-center h-screen w-full bg-cover bg-center"
        style={{
          // backgroundImage: "url('/spotsbg.png')",
          backgroundImage:
            "linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('/spotsbg.png')",
        }}
      >
        <div className="flex flex-col justify-center items-center space-y-7">
          <img
            src="/Spot.svg"
            alt="4WALL SPOTS"
            className="w-4/5 items-center justify-center"
          />
          <p className="text-white text-lg font-main m-0 w-1/2 text-center">
            Hang out with your friends and AI characters in virtual worlds.
          </p>

          {isInWaitlist ? (
            <Chip
              size="lg"
              color="warning"
              className="!bg-orange-500 !text-white  !font-main !font-bold"
            >
              You are on the waitlist!
            </Chip>
          ) : (
            <Button
              variant="outlined"
              size="lg"
              className="!transition-all !duration-200 !text-fourwall-orange !border-fourwall-orange !font-bold !font-main  !rounded-full hover:!bg-fourwall-orange hover:!text-white "
              onClick={handleJoinWaitlist}
              disabled={isWaitlistLoading}
            >
              {isWaitlistLoading ? (
                <CircularProgress className="!text-fourwall-orange" />
              ) : (
                "JOIN WAITLIST"
              )}
            </Button>
          )}
        </div>
        <div className="flex flex-col items-center justify-center m-10">
          <p className="text-white text-sm font-main m-0 w-full text-center">
            Follow us on socials to stay up to date.
          </p>
          <div className="flex flex-row  space-x-4">
            <SocialIcon
              url={socialUrls.Discord}
              target="_blank"
              style={styles.socialIconStyle}
              fgColor="var(--orange-brand-accent)"
              bgColor="transparent"
            />
            <SocialIcon
              url={socialUrls.Reddit}
              target="_blank"
              style={styles.socialIconStyle}
              fgColor="var(--orange-brand-accent)"
              bgColor="transparent"
            />
            <SocialIcon
              url={socialUrls.X}
              target="_blank"
              style={styles.socialIconStyle}
              fgColor="var(--orange-brand-accent)"
              bgColor="transparent"
            />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="flex flex-col items-left bg-neutral-900/80 h-screen w-full">
      {isAuthenticated && user ? (
        <>
          <div className="flex justify-center items-center">
            <img
              src="/Spot.svg"
              alt="4WALL SPOTS"
              className="w-1/4 items-center justify-center mt-10"
            />
            <p className="text-white text-md font-main m-0 w-1/3 text-left mx-2">
              Hang out with your friends and AI characters in virtual worlds.
            </p>
          </div>

          <CreateTownDialog
            dialogOpen={dialogOpen}
            setDialogOpen={setDialogOpen}
            townName={townName}
            setTownName={setTownName}
            isPlanGeneratingId={isPlanGeneratingId}
            selectedCharacters={selectedCharacters}
            handleCharacterChange={handleCharacterChange}
            handlePlanChange={handlePlanChange}
            setSelectedCharacters={setSelectedCharacters}
            generateAIPlan={generateAIPlan}
            createGotoTown={createGotoTown}
          />

          <div className=" flex flex-col items-left p-8 m-0 rounded-xl">
            <h2 className="text-white text-2xl font-bold font-main m-0">
              Your Spots
            </h2>
            <div className="flex flex-row flex-wrap items-center justify-start">
              <Card
                onClick={() => setDialogOpen(true)}
                className="!bg-neutral-800 !rounded-xl flex mr-1 p-4 h-[8rem] w-[12rem] !border-1 !border-solid !border-neutral-700 !shadow-lg !shadow-neutral-800/50 !text-orange-500 hover:cursor-pointer hover:!bg-neutral-700 transition-all duration-200"
              >
                <CardContent className="flex flex-col justify-center items-center">
                  <Plus size={50} />
                </CardContent>
              </Card>

              {towns.map((town) => (
                <TownCard
                  key={town.town_id}
                  townName={town.town_name}
                  townId={town.town_id}
                  onClick={() =>
                    navigate(`/town/${town.town_id}`, {
                      state: { townName: town.town_name },
                    })
                  }
                  refresh={fetchUserTowns}
                />
              ))}
            </div>
          </div>
        </>
      ) : (
        <h1 style={styles.heading}>Please login to access 4Wall Spot</h1>
      )}
      <Modal open={infoOpen} onClose={() => setInfoOpen(false)}>
        <ModalDialog>
          <div style={{ position: "relative" }}>
            <X
              onClick={() => setInfoOpen(false)}
              style={{
                position: "fixed",
                top: "1rem",
                right: "1rem",
                cursor: "pointer",
              }}
            />
            <h1>Create a New Spot</h1>
            <ul>
              <li>
                <strong>Name Your Town:</strong> Enter a unique and fun name for
                your town in the "Town Name" field.
              </li>
              <li>
                <strong>Add Characters:</strong> Select up to 5 characters for
                your town. Choose from the featured list or find your favorites
                using the search bar.
              </li>
              <li>
                <strong>Provide a Plan:</strong> For each character, you can
                provide an objective or goal for them in the town.
              </li>
              <li>
                <strong>Select a Map:</strong> Currently, we have one map
                available, but more maps will be added soon. Eventually, you'll
                also be able to create your own maps.
              </li>
              <li>
                <strong>Create Your Town:</strong> Once you've named your town,
                added characters, and selected a map, click "Create" to bring
                your town to life!
              </li>
            </ul>
          </div>
        </ModalDialog>
      </Modal>
    </div>
  );
}

const styles: { [key: string]: CSSProperties } = {
  heading: {
    textAlign: "center",
    fontFamily: "var(--font_b)",

    color: "var(--primary-text-color)", // You can replace "black" with any color you need
  },
  gridContainer: {
    margin: "1rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "left",

    borderRadius: "0.5em",
    padding: "1rem",
  },
  townsHeading: {
    textAlign: "left",
    color: "white",
    borderBottom: "1px solid var(--orange-brand-accent)",
    paddingBottom: "0.5rem",
  },
  createButton: {
    backgroundColor: "var(--orange-brand-accent)",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    fontFamily: "var(--font_b)",
    margin: "1rem auto ",
  },
};
export default TownPage;
