import React, { useContext, useEffect } from "react";
import Button from "@mui/joy/Button";
import { toast } from "react-toastify";
import { useConvex, useMutation, useQuery } from "convex/react";
import { api } from "../../../convex/_generated/api";
import { ConvexError } from "convex/values";
import { Id } from "../../../convex/_generated/dataModel";
import { useCallback } from "react";
import { waitForInput } from "../../../../src/hooks/sendInput";
import { useServerGame } from "../../../../src/hooks/serverGame";
import { PlatformContext } from "../../../../src/contexts/PlatformContext";
export default function InteractButton({ worldStatus }: any) {
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error("PlatformContext is not defined");
  }
  const { username } = context;
  const worldId = worldStatus?.worldId;
  const game = useServerGame(worldId);
  const humanTokenIdentifier = useQuery(
    api.world.userStatus,
    worldId ? { worldId } : "skip"
  );
  const userPlayerId =
    game &&
    [...game.world.players.values()].find(
      (p) => p.human === humanTokenIdentifier
    )?.id;
  const join = useMutation(api.world.joinWorld);
  const isPlaying = !!userPlayerId;

  const convex = useConvex();
  const joinInput = useCallback(
    async (worldId: Id<"worlds">) => {
      let inputId;
      try {
        inputId = await join({
          worldId,
          userData: { username: username },
        });
      } catch (e: any) {
        if (e instanceof ConvexError) {
          toast.error(e.data);
          return;
        }
        throw e;
      }
      try {
        await waitForInput(convex, inputId);
      } catch (e: any) {
        toast.error(e.message);
      }
    },
    [convex, join]
  );

  useEffect(() => {
    if (worldId && game !== undefined && !isPlaying) {
      console.log(`Joining game`);
      void joinInput(worldId);
    }
  }, [worldId, game, isPlaying, joinInput]);

  return (
    <Button
      style={styles.button}
      color="success"
      startDecorator={
        <img
          src="/town-assets/interact.svg"
          alt="interact"
          style={styles.svg}
        />
      }
      disabled={true}
    >
      Interacting
    </Button>
  );
}

const styles = {
  button: {
    fontFamily: "var(--font_b)",
    color: "white",
    borderRadius: "10px",
    margin: "0 10px",
  },
  svg: {
    width: "20px",
    height: "20px",
    marginRight: "10px",
  },
};
