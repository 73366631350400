import React, { useEffect, useContext } from "react";
import {
  ListItem,
  ListItemDecorator,
  ListItemContent,
  Typography,
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  IconButton,
  Dropdown,
} from "@mui/joy";
import { GameId } from "../../convex/aiTown/ids";
import { Id } from "../../convex/_generated/dataModel";
import { ServerGame } from "../../hooks/serverGame";
import { SelectElement } from "./Player";
import { useState } from "react";
import { toastOnError } from "../../toasts";
import { useQuery } from "convex/react";
import { Ellipsis, MessageCircle, Pencil } from "lucide-react";
import { api } from "../../convex/_generated/api";
import { useMutation } from "convex/react";
import { StyledAvatar } from "./common/styledAvatar";
import AddOrEditInGameCharacter from "../TownPage/AddOrEditInGameCharacter";
import { TownContext } from "../../contexts/TownContext";
import { PlatformContext } from "../../contexts/PlatformContext";
import { ACTIVITIES } from "../../convex/constants";
import { useSendInput } from "../../hooks/sendInput";
import MemoryButton from "./common/MemoryButton";
import useRequest from "../../apis/useRequest";
import { updateTown } from "../../apis/request";
import { SelectedCharacterProps } from "../../types";
import { buildCharacterData } from "../TownPage/TownPage";

export default function SpotMemberDisplay({
  user,
  worldId,
  playerId,
  engineId,
  game,
  setSelectedElement,
  onPlayerClick,
}: {
  user?: boolean;
  worldId: Id<"worlds">;
  playerId: GameId<"players">;
  engineId: Id<"engines">;
  game: ServerGame;
  setSelectedElement: SelectElement;
  onPlayerClick: (player: GameId<"players">) => void;
}) {
  const townContext = useContext(TownContext);
  const context = useContext(PlatformContext);
  if (!townContext || !context) {
    throw new Error("useContext must be used within a TownContext.Provider");
  }
  const { charactersMetadata, setCharactersMetadata, town_id } = townContext;
  const { username } = context;
  const makeRequest = useRequest();
  const updateCharacters = useMutation(api.world.updateCharacters);
  const [showEditPlayer, setShowEditPlayer] = useState(false);
  const [agentEmoji, setAgentEmoji] = useState<string | undefined>(undefined);
  const player = game.world.players.get(playerId);
  const agentId = [...game.world.agents.values()].find(
    (agent) => agent.playerId === playerId
  )?.id;
  const playerConversation = player && game.world.playerConversation(player);
  const descriptions = useQuery(api.world.gameDescriptions, { worldId });
  const playerDescription = playerId && game.playerDescriptions.get(playerId);
  const playerStatus =
    playerConversation && playerConversation.participants.get(playerId)?.status;
  const participants = playerConversation && playerConversation.participants;
  const otherParticipants =
    participants &&
    [...participants.values()].filter((p) => p.playerId !== playerId);
  const otherParticipantsIds = otherParticipants?.map((p) => p.playerId);
  const removeCharacters = useMutation(api.world.removeCharacters);
  const otherParticipantsNames = otherParticipantsIds?.map(
    (id) =>
      descriptions?.playerDescriptions.find((p: any) => p.playerId === id)?.name
  );
  const players = [...game.world.players.values()];
  const humanTokenIdentifier = useQuery(api.world.userStatus, { worldId });
  const humanPlayer = players.find((p) => p.human === humanTokenIdentifier);
  const humanConversation = humanPlayer
    ? game.world.playerConversation(humanPlayer)
    : undefined;

  // This player can be invited by the human.
  const isMe = humanPlayer && player?.id === humanPlayer.id;
  const canInvite =
    !isMe && !playerConversation && humanPlayer && !humanConversation;

  // Waiting for accept check
  const sameConversation =
    !isMe &&
    humanPlayer &&
    humanConversation &&
    playerConversation &&
    humanConversation.id === playerConversation.id;
  const waitingForAccept =
    sameConversation &&
    playerConversation.participants.get(playerId)?.status.kind === "invited";

  // Walking over check
  const humanStatus =
    humanPlayer &&
    humanConversation &&
    humanConversation.participants.get(humanPlayer.id)?.status;
  const haveInvitedHuman =
    sameConversation && humanStatus?.kind === "invited" && !user;
  const waitingForNearby =
    sameConversation &&
    playerStatus?.kind === "walkingOver" &&
    humanStatus?.kind === "walkingOver";

  const inConversationWithMe =
    sameConversation &&
    playerStatus?.kind === "participating" &&
    humanStatus?.kind === "participating";

  useEffect(() => {
    if (inConversationWithMe) {
      console.log("In conversation with me", playerId);
      handlePlayerClick();
    }
  }, [inConversationWithMe]);

  const onStartConversation = async () => {
    if (!humanPlayer || !playerId) {
      return;
    }
    console.log(`Starting conversation`);
    await toastOnError(
      startConversation({ playerId: humanPlayer.id, invitee: playerId })
    );
  };
  const onAcceptInvite = async () => {
    if (!humanPlayer || !humanConversation || !playerId) {
      return;
    }
    await toastOnError(
      acceptInvite({
        playerId: humanPlayer.id,
        conversationId: humanConversation.id,
      })
    );
    // MAYBE ALERT FOR ACCEPTING INVITE
  };
  const onRejectInvite = async () => {
    if (!humanPlayer || !humanConversation) {
      return;
    }
    await toastOnError(
      rejectInvite({
        playerId: humanPlayer.id,
        conversationId: humanConversation.id,
      })
    );
    // MAYBE ALERT FOR REJECTING INVITE
  };
  const startConversation = useSendInput(engineId, "startConversation");
  const acceptInvite = useSendInput(engineId, "acceptInvite");
  const rejectInvite = useSendInput(engineId, "rejectInvite");
  const action =
    playerStatus?.kind === "walkingOver"
      ? "walking over to"
      : playerStatus?.kind === "invited"
        ? "invited"
        : playerStatus?.kind === "participating"
          ? "talking to"
          : "";

  useEffect(() => {
    if (action === "talking to") {
      setAgentEmoji("💬");
    }
    if (
      !playerConversation &&
      player?.activity &&
      player?.activity.until > Date.now()
    ) {
      setAgentEmoji(player?.activity?.emoji);
    }
  }, [action, player?.activity]);
  const { width, height, tileDim } = game.worldMap;
  const handlePlayerClick = () => {
    console.log("Player clicked !!!!!!!!!", playerId);
    onPlayerClick(playerId);
  };

  const handleUpdate = async (character: SelectedCharacterProps) => {
    const awsCharacterData: any = charactersMetadata.map((char) =>
      char.character_id === character.character_id
        ? {
            ...char,
            name: character.name,
            bio: character.bio,
            character: character.character_id,
            description: character.description,
            image_url: `${process.env.REACT_APP_ASSETS_BUCKET}${character.character_id}/display_picture.jpg`,
            plan: character.plan,
            activities: character.activities,
          }
        : char
    );

    const townCharacterData = [
      {
        name: character.name || "",
        identity: character.description,
        bio: character.bio || "",
        plan: character.plan,
        image: `${process.env.REACT_APP_ASSETS_BUCKET}${character.character_id}/display_picture`,
        character: playerDescription?.character || "",
        character_id: character.character_id,
        activities: character.activities,
      },
    ];
    await updateCharacters({
      worldId: town_id as Id<"worlds">,
      agentId: agentId as string,
      playerId: playerId as string,
      characters: townCharacterData,
    });
    const response = await makeRequest<any, any>(
      updateTown(username, town_id, "characters"),
      { updatedCharacters: awsCharacterData }
    );
    setCharactersMetadata(awsCharacterData);
    console.log("Update response", response);
  };

  const handleKick = async (
    playerId: GameId<"players">,
    agentId: GameId<"agents"> | undefined
  ) => {
    try {
      if (agentId) {
        console.log("Kicking", agentId, playerId);
        removeCharacters({
          worldId,
          characters: [{ agentId: agentId, playerId }],
        });

        const response = await makeRequest<any, any>(
          updateTown(username, town_id, "characters"),
          {
            updatedCharacters: charactersMetadata.filter(
              (character) =>
                character.character_id !== playerDescription?.character_id
            ),
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  };
  if (!player) {
    return null;
  }
  return (
    <>
      <ListItem
        className={`hover:!bg-neutral-700 !rounded-lg !my-2 group ${
          haveInvitedHuman
            ? "!bg-orange-900/50 !border-orange-500 !border-solid !border-2"
            : ""
        }`}
        onClick={handlePlayerClick}
      >
        <ListItemDecorator className="!w-1/3 ">
          <StyledAvatar
            size={user ? "small" : "medium"}
            src={
              user
                ? `${process.env.REACT_APP_ASSETS_BUCKET}users/${username}/display_picture.jpg`
                : `${process.env.REACT_APP_ASSETS_BUCKET}${playerDescription?.character_id}/display_picture.jpg` ||
                  "/chatdp.png"
            }
            alt="town_player"
            emoji={agentEmoji}
          />
        </ListItemDecorator>
        <ListItemContent className="!w-2/3">
          <Box className="flex flex-col items-left justify-center !mx-2">
            <Typography className="!text-white  !font-main !text-md whitespace-nowrap !my-0">
              {playerDescription?.name}
            </Typography>
            {playerStatus && (
              <p className="!text-gray-300 !text-xs  !font-main !my-0">
                {action} {action === "invited" ? "by " : ""}
                {otherParticipantsNames?.join(", ")}
              </p>
            )}
            {!playerConversation &&
              player.activity &&
              player.activity.until > Date.now() && (
                <p className="!text-gray-300 !text-xs  !font-main !my-0">
                  {player.activity?.description}
                </p>
              )}
            {haveInvitedHuman && (
              <div className="flex flex-row gap-2">
                <Button
                  size="sm"
                  color="success"
                  onClick={(e) => {
                    e.stopPropagation();
                    onAcceptInvite();
                  }}
                >
                  Accept
                </Button>
                <Button
                  size="sm"
                  color="danger"
                  onClick={(e) => {
                    e.stopPropagation();
                    onRejectInvite();
                  }}
                >
                  Reject
                </Button>
              </div>
            )}
          </Box>
        </ListItemContent>

        {!user && (
          <Dropdown>
            <MenuButton
              slots={{ root: IconButton }}
              slotProps={{ root: { variant: "plain", color: "neutral" } }}
              onClick={(e) => {
                e.stopPropagation();
              }}
              className="hover:!bg-neutral-900 !rounded-full"
              sx={{
                opacity: 0,
                ".group:hover &": {
                  opacity: 1,
                },
              }}
            >
              <Ellipsis className="!text-white " />
            </MenuButton>
            <Menu className="!bg-neutral-900 !border-2 !border-neutral-800 !border-solid">
              <MenuItem
                className="!text-white"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowEditPlayer(true);
                }}
              >
                <IconButton aria-label="Edit">
                  <Pencil className="!text-fourwall-orange" />
                </IconButton>
                Edit
              </MenuItem>
              <MenuItem
                disabled={!canInvite}
                className={`!text-white ${
                  !canInvite
                    ? "!opacity-50 !cursor-not-allowed !text-gray-500"
                    : ""
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  if (canInvite) {
                    onStartConversation();
                  }
                }}
              >
                <IconButton
                  disabled={!canInvite}
                  aria-label="Start Conversation"
                >
                  <MessageCircle
                    className={`!text-fourwall-orange ${
                      !canInvite
                        ? "!opacity-50 !cursor-not-allowed !text-gray-500"
                        : ""
                    }`}
                  />
                </IconButton>
                Start Conversation
              </MenuItem>
              {/* <MenuItem
              className="!text-white"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedElement({ kind: "player", id: playerId });
              }}
            >
              <MemoryButton
                onClick={(e) => {
                  e.stopPropagation();
                }}
              ></MemoryButton>
              View Memory
            </MenuItem> */}
            </Menu>
          </Dropdown>
        )}
      </ListItem>
      {!user && (
        <AddOrEditInGameCharacter
          playerId={playerId}
          open={showEditPlayer}
          setOpen={setShowEditPlayer}
          character={
            charactersMetadata?.find(
              (character) =>
                character.character_id === playerDescription?.character_id
            ) || {
              character_id: "",
              description: "",
              bio: "",
              plan: "",
              image_url: "",
              activities: ACTIVITIES,
            }
          }
          inGame={true}
          editing={true}
          onAddCharacter={() => {}}
          onKickCharacter={() => handleKick(playerId, agentId)}
          onUpdateCharacter={(character) => {
            handleUpdate(character);
          }}
        />
      )}
    </>
  );
}
