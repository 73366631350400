import React, { useCallback } from "react";
import * as PIXI from "pixi.js";
import { useApp } from "@pixi/react";
import { Player, SelectElement } from "./Player";
import { useEffect, useRef, useState } from "react";
import { PixiStaticMap } from "./PixiStaticMap";
import PixiViewport from "./PixiViewport";
import { Viewport } from "pixi-viewport";
import { Id } from "../../convex/_generated/dataModel";
import { useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import { useSendInput } from "../../hooks/sendInput";
import { toastOnError } from "../../toasts";
import { DebugPath } from "./DebugPath";
import { PositionIndicator } from "./PositionIndicator";
import { SHOW_DEBUG_UI } from "./Game";
import { ServerGame } from "../../hooks/serverGame";
import { GameId } from "../../convex/aiTown/ids";
import { movePlayer } from "../../convex/aiTown/movement";
import { Game } from "../../convex/aiTown/game";

export const PixiGame = (props: {
  worldId: Id<"worlds">;
  engineId: Id<"engines">;
  game: ServerGame;
  historicalTime: number | undefined;
  width: number;
  height: number;
  setSelectedElement: SelectElement;
  worldStatus: any;
  focusedPlayerId: GameId<"players"> | undefined;
}) => {
  // PIXI setup.
  const pixiApp = useApp();
  const viewportRef = useRef<Viewport | undefined>(undefined);

  const humanTokenIdentifier =
    useQuery(api.world.userStatus, { worldId: props.worldId }) ?? null;
  const humanPlayerId = [...props.game.world.players.values()].find(
    (p) => p.human === humanTokenIdentifier
  )?.id;
  const humanPlayer = humanPlayerId
    ? props.game.world.players.get(humanPlayerId)
    : null;
  const moveTo = useSendInput(props.engineId, "moveTo");

  // Interaction for clicking on the world to navigate.
  const dragStart = useRef<{ screenX: number; screenY: number } | null>(null);
  const onMapPointerDown = (e: any) => {
    // https://pixijs.download/dev/docs/PIXI.FederatedPointerEvent.html
    dragStart.current = { screenX: e.screenX, screenY: e.screenY };
  };

  const [lastDestination, setLastDestination] = useState<{
    x: number;
    y: number;
    t: number;
  } | null>(null);

  const [arrowKeys, setArrowKeys] = useState({
    ArrowUp: false,
    ArrowDown: false,
    ArrowLeft: false,
    ArrowRight: false,
  });

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (Object.keys(arrowKeys).includes(event.key)) {
      setArrowKeys((prev) => ({ ...prev, [event.key]: true }));
    }
  }, []);

  const handleKeyUp = useCallback((event: KeyboardEvent) => {
    if (Object.keys(arrowKeys).includes(event.key)) {
      setArrowKeys((prev) => ({ ...prev, [event.key]: false }));
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp]);

  useEffect(() => {
    if (humanPlayer && humanPlayerId) {
      const newPosition = { ...humanPlayer.position };
      if (arrowKeys.ArrowUp) newPosition.y -= 0.7;
      if (arrowKeys.ArrowDown) newPosition.y += 0.7;
      if (arrowKeys.ArrowLeft) newPosition.x -= 0.7;
      if (arrowKeys.ArrowRight) newPosition.x += 0.7;

      if (
        newPosition.x !== humanPlayer.position.x ||
        newPosition.y !== humanPlayer.position.y
      ) {
        const roundedPosition = {
          x: Math.round(newPosition.x),
          y: Math.round(newPosition.y),
        };
        moveTo({ playerId: humanPlayerId, destination: roundedPosition });
      }
    }
  }, [arrowKeys, humanPlayer, humanPlayerId, moveTo]);

  const onMapPointerUp = async (e: any) => {
    if (dragStart.current) {
      const { screenX, screenY } = dragStart.current;
      dragStart.current = null;
      const [dx, dy] = [screenX - e.screenX, screenY - e.screenY];
      const dist = Math.sqrt(dx * dx + dy * dy);
      if (dist > 10) {
        console.log(`Skipping navigation on drag event (${dist}px)`);
        return;
      }
    }
    if (!humanPlayerId) {
      return;
    }
    const viewport = viewportRef.current;

    if (!viewport) {
      return;
    }
    const gameSpacePx = viewport.toWorld(e.screenX, e.screenY);
    const tileDim = props.game.worldMap.tileDim;
    const gameSpaceTiles = {
      x: gameSpacePx.x / tileDim,
      y: gameSpacePx.y / tileDim,
    };
    setLastDestination({ t: Date.now(), ...gameSpaceTiles });
    const roundedTiles = {
      x: Math.floor(gameSpaceTiles.x),
      y: Math.floor(gameSpaceTiles.y),
    };
    console.log(`Moving to ${JSON.stringify(roundedTiles)}`);
    await toastOnError(
      moveTo({ playerId: humanPlayerId, destination: roundedTiles })
    );
  };
  const { width, height, tileDim } = props.game.worldMap;
  const players = [...props.game.world.players.values()];

  // Zoom on the user’s avatar when it is created
  useEffect(() => {
    if (!viewportRef.current || humanPlayerId === undefined) return;

    const humanPlayer = props.game.world.players.get(humanPlayerId)!;
    viewportRef.current.animate({
      position: new PIXI.Point(
        humanPlayer.position.x * tileDim,
        humanPlayer.position.y * tileDim
      ),
      scale: 1.5,
    });
  }, [humanPlayerId]);

  // Gymnastics to zoom on the focused player
  useEffect(() => {
    if (!viewportRef.current || !props.focusedPlayerId) return;

    const player = props.game.world.players.get(props.focusedPlayerId);
    if (!player) return;

    viewportRef.current.animate({
      position: new PIXI.Point(
        player.position.x * props.game.worldMap.tileDim,
        player.position.y * props.game.worldMap.tileDim
      ),
      scale: 1.5,
    });
  }, [props.focusedPlayerId]);

  return (
    <PixiViewport
      app={pixiApp}
      screenWidth={props.width}
      screenHeight={props.height}
      worldWidth={width * tileDim}
      worldHeight={height * tileDim}
      viewportRef={viewportRef}
    >
      <PixiStaticMap
        map={props.game.worldMap}
        onpointerup={onMapPointerUp}
        onpointerdown={onMapPointerDown}
      />
      {players.map(
        (p) =>
          // Only show the path for the human player in non-debug mode.
          (SHOW_DEBUG_UI || p.id === humanPlayerId) && (
            <DebugPath key={`path-${p.id}`} player={p} tileDim={tileDim} />
          )
      )}
      {lastDestination && (
        <PositionIndicator destination={lastDestination} tileDim={tileDim} />
      )}
      {players.map((p) => (
        <Player
          key={`player-${p.id}`}
          game={props.game}
          player={p}
          isViewer={p.id === humanPlayerId}
          onClick={props.setSelectedElement}
          historicalTime={props.historicalTime}
        />
      ))}
    </PixiViewport>
  );
};
export default PixiGame;
