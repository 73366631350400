import { RequestDefinition, RequestMethod, RequestName } from "./types";

export function getUser(username: string): RequestDefinition {
  return {
    name: RequestName.GET_USER,
    path: `/user/${username}`,
    method: RequestMethod.GET,
    shouldChangeContext: false,
  };
}
export function getUserAssets(
  username: string,
  other: string = "false"
): RequestDefinition {
  return {
    name: RequestName.GET_USER_ASSETS,
    path: `/assets/${username}`,
    method: RequestMethod.GET,
    shouldChangeContext: false,
    queryParams: { other: other },
  };
}

export function updatePersona(persona_id: string): RequestDefinition {
  return {
    name: RequestName.UPDATE_PERSONA,
    path: `/persona/${persona_id}`,
    method: RequestMethod.PUT,
    shouldChangeContext: false,
  };
}

export function createPersona(persona_id: string): RequestDefinition {
  return {
    name: RequestName.CREATE_PERSONA,
    path: `/persona/${persona_id}`,
    method: RequestMethod.POST,
    shouldChangeContext: false,
  };
}

export function getPersona(persona_id: string): RequestDefinition {
  return {
    name: RequestName.GET_PERSONA,
    path: `/persona/${persona_id}`,
    method: RequestMethod.GET,
    shouldChangeContext: false,
  };
}

export function deletePersona(persona_id: string): RequestDefinition {
  return {
    name: RequestName.DELETE_PERSONA,
    path: `/persona/${persona_id}`,
    method: RequestMethod.DELETE,
    shouldChangeContext: false,
  };
}
export function createCharacter(
  username: string,
  character_id: string
): RequestDefinition {
  return {
    name: RequestName.CREATE_CHARACTER,
    path: `/character/${username}/${character_id}`,
    method: RequestMethod.POST,
    shouldChangeContext: false,
  };
}
export function updateCharacter(
  username: string,
  character_id: string
): RequestDefinition {
  return {
    name: RequestName.UPDATE_CHARACTER,
    path: `/character/${username}/${character_id}`,
    method: RequestMethod.PUT,
    shouldChangeContext: false,
  };
}

export function getCharacter(
  username: string,
  character_id: string
): RequestDefinition {
  return {
    name: RequestName.GET_CHARACTER,
    path: `/character/${username}/${character_id}`,
    method: RequestMethod.GET,
    shouldChangeContext: false,
  };
}

export function deleteCharacter(
  username: string,
  character_id: string
): RequestDefinition {
  return {
    name: RequestName.DELETE_CHARACTER,
    path: `/character/${username}/${character_id}`,
    method: RequestMethod.DELETE,
    shouldChangeContext: false,
  };
}
export function getChatMessages(
  chat_id: string | undefined,
  username: string
): RequestDefinition {
  return {
    name: RequestName.GET_CHAT_MESSAGES,
    path: `/chat/${chat_id}`,
    method: RequestMethod.GET,
    shouldChangeContext: false,
    queryParams: { username: username },
  };
}
export function getChatMetadata(chat_id: string): RequestDefinition {
  return {
    name: RequestName.GET_CHAT_METADATA,
    path: `/chat/metadata/${chat_id}`,
    method: RequestMethod.GET,
    shouldChangeContext: false,
  };
}

export function deleteChat(chat_id: string): RequestDefinition {
  return {
    name: RequestName.DELETE_CHAT,
    path: `/chat/${chat_id}`,
    method: RequestMethod.DELETE,
    shouldChangeContext: false,
  };
}

export function getFeaturedCharacters(): RequestDefinition {
  return {
    name: RequestName.GET_FEATURED_CHARACTERS,
    path: `/landing/characters`,
    method: RequestMethod.GET,
    shouldChangeContext: false,
  };
}

export function updateMessage(
  message_id: string,
  chat_id: string | undefined,
  operation: "edit" | "regen"
): RequestDefinition {
  return {
    name: RequestName.UPDATE_MESSAGE,
    path: `/message/${chat_id}/${message_id}`,
    method: RequestMethod.PUT,
    shouldChangeContext: false,
    queryParams: { operation: operation },
  };
}

export function deleteMessage(
  message_id: string,
  chat_id: string | undefined
): RequestDefinition {
  return {
    name: RequestName.DELETE_MESSAGE,
    path: `/message/${chat_id}/${message_id}`,
    method: RequestMethod.DELETE,
    shouldChangeContext: false,
  };
}

export function getTown(username: string, town_id: string): RequestDefinition {
  return {
    name: RequestName.GET_TOWN,
    path: `/town/${username}/${town_id}`,
    method: RequestMethod.GET,
    shouldChangeContext: false,
  };
}

export function getTowns(username: string): RequestDefinition {
  return {
    name: RequestName.GET_TOWNS,
    path: `/town/${username}`,
    method: RequestMethod.GET,
    shouldChangeContext: false,
  };
}

export function createTown(username: string): RequestDefinition {
  return {
    name: RequestName.CREATE_TOWN,
    path: `/town/${username}`,
    method: RequestMethod.POST,
    shouldChangeContext: false,
  };
}

export function getTownCharacterData(characters: string): RequestDefinition {
  return {
    name: RequestName.GET_TOWN_CHARACTER_DATA,
    path: `/town/characterdata?characters=${characters}`,
    method: RequestMethod.GET,
    shouldChangeContext: false,
  };
}

export function deleteTown(
  username: string,
  town_id: string
): RequestDefinition {
  return {
    name: RequestName.DELETE_TOWN,
    path: `/town/${username}/${town_id}`,
    method: RequestMethod.DELETE,
    shouldChangeContext: false,
  };
}
export function updateTown(
  username: string,
  town_id: string,
  field: string
): RequestDefinition {
  return {
    name: RequestName.UPDATE_TOWN,
    path: `/town/${username}/${town_id}`,
    method: RequestMethod.PUT,
    shouldChangeContext: false,
    queryParams: { field: field },
  };
}
export function deleteUser(username: string): RequestDefinition {
  return {
    name: RequestName.DELETE_USER,
    path: `/user/${username}`,
    method: RequestMethod.DELETE,
    shouldChangeContext: false,
  };
}

export function addToWaitlist(username: string): RequestDefinition {
  return {
    name: RequestName.ADD_TO_WAITLIST,
    path: `/misc/waitlist/${username}`,
    method: RequestMethod.POST,
    shouldChangeContext: false,
  };
}

export function getIfUserInWaitlist(username: string): RequestDefinition {
  return {
    name: RequestName.GET_USER_WAITLIST,
    path: `/misc/waitlist/${username}`,
    method: RequestMethod.GET,
    shouldChangeContext: false,
  };
}

export function uploadImage(): RequestDefinition {
  return {
    name: RequestName.UPLOAD_IMAGE,
    path: `/upload/image`,
    method: RequestMethod.POST,
    shouldChangeContext: false,
  };
}

export function updateScenario(
  username: string,
  chat_id: string | undefined,
  character_id: string
): RequestDefinition {
  return {
    name: RequestName.UPDATE_SCENARIO,
    path: `/chat/scenario/${username}`,
    method: RequestMethod.POST,
    shouldChangeContext: false,
    queryParams: { chat_id: chat_id, character_id: character_id },
  };
}

export function auxLLM(): RequestDefinition {
  return {
    name: RequestName.AUX_LLM,
    path: `/llm/generate`,
    method: RequestMethod.POST,
    shouldChangeContext: false,
  };
}

export function characterSearch(): RequestDefinition {
  return {
    name: RequestName.CHARACTER_SEARCH,
    path: `/search/characters`,
    method: RequestMethod.POST,
    shouldChangeContext: false,
  };
}
