import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Id } from "../../convex/_generated/dataModel";
import { useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import { TownContext } from "../../contexts/TownContext";
import Game from "../Town/Game";
import { SelectedCharacterProps } from "../../types";
import { getTown } from "../../apis/request";
import useRequest from "../../apis/useRequest";
import { PlatformContext } from "../../contexts/PlatformContext";
function Walltown() {
  const { town_id } = useParams<{ town_id: string }>();
  const location = useLocation();
  const [townName, setTownName] = useState(location.state?.townName);
  const [charactersMetadata, setCharactersMetadata] = useState<
    SelectedCharacterProps[]
  >([]);
  if (!town_id) {
    throw new Error("town_id is undefined");
  }
  const makeRequest = useRequest();
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error("PlatformContext is undefined");
  }
  const { username } = context;
  useEffect(() => {
    async function fetchData() {
      const response = await makeRequest<any, any>(
        getTown(username, town_id as string)
      );
      console.log("response getTown", response);
      setCharactersMetadata(response.characters);

      setTownName(response.town_name);
    }
    if (location.state?.charactersMetadata) {
      setCharactersMetadata(location.state.charactersMetadata);
    } else {
      fetchData();
    }
  }, []);
  const castTownId: Id<"worlds"> = town_id as Id<"worlds">;
  const result = useQuery(api.world.getWorldStatus, {
    worldId: castTownId,
  });
  const worldStatus = result?.worldStatus;

  return (
    <>
      <TownContext.Provider
        value={{
          townName,
          setTownName,
          town_id,
          charactersMetadata,
          setCharactersMetadata,
        }}
      >
        <Game worldStatus={worldStatus} townName={townName} />
      </TownContext.Provider>
    </>
  );
}

export default Walltown;
