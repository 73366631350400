import React, { useState, useEffect, useContext } from "react";
import {
  Modal,
  ModalDialog,
  ModalClose,
  DialogTitle,
  DialogContent,
  Button,
  Input,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/joy";
import { SelectedCharacterProps } from "../../types";
import EmojiPicker from "emoji-picker-react";
import { RefreshCcw, CircleMinus } from "lucide-react";
import { StyledAvatar } from "../Town/common/styledAvatar";
import useRequest from "../../apis/useRequest";
import { auxLLM } from "../../apis/request";
import { api } from "../../convex/_generated/api";
import { useMutation } from "convex/react";
import { GameId } from "../../convex/aiTown/ids";
import { Id } from "../../convex/_generated/dataModel";

function AddOrEditInGameCharacter({
  open,
  setOpen,
  character,
  onAddCharacter,
  onUpdateCharacter,
  editing,
  inGame,
  onKickCharacter,
}: {
  playerId?: GameId<"players">;
  open: boolean;
  setOpen: (open: boolean) => void;
  character: SelectedCharacterProps;
  onAddCharacter: (character: SelectedCharacterProps) => void;
  onUpdateCharacter?: (character: SelectedCharacterProps) => void;
  editing?: boolean;
  inGame?: boolean;
  onKickCharacter?: () => void;
}) {
  const makeRequest = useRequest();
  const [planGenerating, setPlanGenerating] = useState(false);
  const [kickModalOpen, setKickModalOpen] = useState(false);
  const [editedCharacter, setEditedCharacter] =
    useState<SelectedCharacterProps>();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [currentActivityIndex, setCurrentActivityIndex] = useState<
    number | null
  >(null);
  const handleClickOutside = (event: MouseEvent) => {
    const emojiPicker = document.getElementById("emoji-picker");
    if (emojiPicker && !emojiPicker.contains(event.target as Node)) {
      setShowEmojiPicker(false);
    }
  };
  const updateCharacters = useMutation(api.world.updateCharacters);

  const generateAIPlan = async (
    character: SelectedCharacterProps
  ): Promise<string> => {
    console.log("Generating Plan");
    setPlanGenerating(true);
    const systemPrompt =
      "You are a helpful assistant that generates a plan for a character based on the given context. \
      The plan should be a short, concise, and relevant objective for the character to complete. \
      The character is an agent in a virtual world. Only generate a one or two sentence plan for the character. \
      The plan should be relevant to the character description and something that the character would want to do.\
      Always create a plan even if context is not provided.\
      ";
    const userPrompt = `Generate a plan for ${character.name} based on the following context: ${character.description}.`;
    const response = await makeRequest<any, any>(auxLLM(), {
      messages: [
        { role: "system", content: systemPrompt },
        { role: "user", content: userPrompt },
      ],
    });
    console.log("Openai response", response);

    if (!response.content) {
      throw new Error("Error generating plan");
    }
    const plan = response.content || "";
    setPlanGenerating(false);
    return plan;
  };
  const handleActivityChange = (index: number, field: any, value: string) => {
    if (editedCharacter) {
      const newActivities = [...editedCharacter.activities];
      newActivities[index] = { ...newActivities[index], [field]: value };
      setEditedCharacter({ ...editedCharacter, activities: newActivities });
    }
  };

  const handleEmojiClick = (emojiObject: any, index: number) => {
    handleActivityChange(index, "emoji", emojiObject.emoji);
    setShowEmojiPicker(false);
  };

  useEffect(() => {
    if (open) {
      setEditedCharacter(character);

      if (!editing) {
        const generatePlan = async () => {
          if (character) {
            const plan = await generateAIPlan(character);
            setEditedCharacter((prevCharacter) =>
              prevCharacter ? { ...prevCharacter, plan } : undefined
            );
          }
        };
        generatePlan();
      }
    }
  }, [character, editing, open]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  if (!character || !editedCharacter) return null;
  const handlePlanChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (editedCharacter) {
      setEditedCharacter({ ...editedCharacter, plan: e.target.value });
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        style={{
          backdropFilter: inGame ? "blur(0px)" : "blur(5px)",
        }}
      >
        <ModalDialog className="!bg-neutral-900 !rounded-xl !border-neutral-800 !text-white w-[50%] items-center">
          <ModalClose
            onClick={() => {
              setOpen(false);
              setEditedCharacter(character);
            }}
          />
          <div className="flex items-center gap-2">
            <StyledAvatar
              src={`${process.env.REACT_APP_ASSETS_BUCKET}${character.character_id}/display_picture.jpg`}
              alt={character.name || ""}
            />
            <DialogTitle className="text-center">{character.name}</DialogTitle>
          </div>
          <DialogContent className="flex flex-col items-center !font-main !p-2">
            <div className="bg-white rounded-lg p-2 mb-4">
              <div className="h-32 w-32 bg-gray-200 rounded-lg">
                SPACE FOR SPRITE
              </div>
            </div>
            <div className="w-full justify-center text-white ">
              <h2 className="text-lg font-semibold mb-2">Plan</h2>
              <div className="flex items-center w-full gap-2">
                <div className="flex items-center gap-2 w-full">
                  {planGenerating ? (
                    <div className="flex items-center gap-2">
                      <Typography className="!text-orange-100 !font-main !italic">
                        Generating Plan...
                      </Typography>
                      <CircularProgress size="sm" color="warning" />
                    </div>
                  ) : (
                    <>
                      <Input
                        value={editedCharacter?.plan}
                        onChange={handlePlanChange}
                        color="warning"
                        className="!bg-black !text-sm !border-none !text-white !rounded-lg !py-2 !px-4 !w-full"
                      />
                      <RefreshCcw
                        size={16}
                        color="var(--primary-text-color)"
                        style={{ cursor: "pointer" }}
                        onClick={async () => {
                          const plan = await generateAIPlan(character);
                          setEditedCharacter((prevCharacter) =>
                            prevCharacter
                              ? { ...prevCharacter, plan }
                              : undefined
                          );
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="w-full justify-center text-white">
              <h2 className="text-lg font-semibold mb-2">Actions</h2>
              <div className="grid grid-cols-3 gap-2 w-full">
                {editedCharacter?.activities.map((activity, index) => (
                  <div
                    key={index}
                    className="bg-gray-600 rounded p-1 flex items-center relative"
                  >
                    <IconButton
                      onClick={() => {
                        setCurrentActivityIndex(index);
                        setShowEmojiPicker(true);
                      }}
                      className="!text-2xl !m-1 hover:!bg-neutral-900"
                    >
                      {activity.emoji}
                    </IconButton>
                    <Input
                      value={activity.description}
                      color="warning"
                      onChange={(e) =>
                        handleActivityChange(
                          index,
                          "description",
                          e.target.value
                        )
                      }
                      className="!text-xs !text-center !bg-neutral-900 !border-none !text-white"
                    />
                  </div>
                ))}
              </div>
            </div>
            {showEmojiPicker && (
              <div id="emoji-picker" className="absolute z-10">
                <EmojiPicker
                  onEmojiClick={(emojiObject) =>
                    handleEmojiClick(emojiObject, currentActivityIndex!)
                  }
                />
              </div>
            )}
            <Button
              className="!mt-4 !bg-fourwall-orange !text-white !rounded-full !px-5 !py-2 hover:!bg-orange-500"
              onClick={() => {
                inGame && editing
                  ? onUpdateCharacter && onUpdateCharacter(editedCharacter)
                  : onAddCharacter(editedCharacter);

                setOpen(false);
              }}
            >
              {editing ? "UPDATE" : "ADD"}
            </Button>
            {inGame && (
              <Button
                className="!absolute !bottom-2 !right-2 !m-2 !bg-black !border-2 !border-neutral-800 !border-solid hover:!bg-neutral-900"
                onClick={() => {
                  setKickModalOpen(true);
                }}
                startDecorator={<CircleMinus size={16} />}
              >
                KICK
              </Button>
            )}
          </DialogContent>
        </ModalDialog>
      </Modal>
      <Modal open={kickModalOpen} onClose={() => setKickModalOpen(false)}>
        <ModalDialog className="!bg-orange-900 !rounded-xl !border-neutral-800 !text-white w-[40%] items-center">
          <ModalClose
            onClick={() => {
              setKickModalOpen(false);
            }}
          />
          <DialogTitle className="text-center text-2xl">
            Kick {character.name} out of this Spot?
          </DialogTitle>
          <DialogContent className="flex flex-col items-center !font-main !p-2">
            <Typography className="!text-orange-100 !font-main !italic">
              This cannot be undone.
            </Typography>
          </DialogContent>
          <Button
            className="!mt-4 !bg-fourwall-orange !text-white  !px-5 !py-2 hover:!bg-orange-500"
            onClick={() => {
              //   onAddCharacter(editedCharacter);
              console.log("Kicking", character);
              onKickCharacter && onKickCharacter();
              setKickModalOpen(false);
            }}
          >
            KICK
          </Button>
        </ModalDialog>
      </Modal>
    </>
  );
}

export default AddOrEditInGameCharacter;
