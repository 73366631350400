import React from "react";
import clsx from "clsx";
import { Doc, Id } from "../../convex/_generated/dataModel";
import { useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import { MessageInput } from "./MessageInput";
import { Player } from "../../convex/aiTown/player";
import { Conversation } from "../../convex/aiTown/conversation";
import { useEffect, useRef } from "react";
import { Avatar } from "@mui/joy";

export function Messages({
  focusedPlayer,
  worldId,
  engineId,
  conversation,
  inConversationWithMe,
  humanPlayer,
  scrollViewRef,
}: {
  focusedPlayer: Player;
  worldId: Id<"worlds">;
  engineId: Id<"engines">;
  conversation:
    | { kind: "active"; doc: Conversation }
    | { kind: "archived"; doc: Doc<"archivedConversations"> };
  inConversationWithMe: boolean;
  humanPlayer?: Player;
  scrollViewRef: React.RefObject<HTMLDivElement>;
}) {
  const humanPlayerId = humanPlayer?.id;
  const descriptions = useQuery(api.world.gameDescriptions, { worldId });
  const messages = useQuery(api.messages.listMessages, {
    worldId,
    conversationId: conversation.doc.id,
  });
  let currentlyTyping =
    conversation.kind === "active" ? conversation.doc.isTyping : undefined;
  if (messages !== undefined && currentlyTyping) {
    if (
      messages.find((m: any) => m.messageUuid === currentlyTyping!.messageUuid)
    ) {
      currentlyTyping = undefined;
    }
  }
  const currentlyTypingName =
    currentlyTyping &&
    descriptions?.playerDescriptions.find(
      (p: any) => p.playerId === currentlyTyping?.playerId
    )?.name;

  const scrollView = scrollViewRef.current;
  const isScrolledToBottom = useRef(false);
  useEffect(() => {
    if (!scrollView) return undefined;

    const onScroll = () => {
      isScrolledToBottom.current = !!(
        scrollView &&
        scrollView.scrollHeight - scrollView.scrollTop - 50 <=
          scrollView.clientHeight
      );
    };
    scrollView.addEventListener("scroll", onScroll);
    return () => scrollView.removeEventListener("scroll", onScroll);
  }, [scrollView]);
  useEffect(() => {
    if (isScrolledToBottom.current) {
      scrollViewRef.current?.scrollTo({
        top: scrollViewRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [messages, currentlyTyping]);

  // console.log("messages DEBUG", messages);
  if (messages === undefined) {
    return null;
  }

  if (messages.length === 0 && !inConversationWithMe) {
    return null;
  }
  console.log("messages DEBUG after", messages);
  const messageNodes: { time: number; node: React.ReactNode }[] = messages.map(
    (m: any) => {
      const isRightAligned = m.author !== focusedPlayer.id;
      const node = (
        <div
          key={`text-${m._id}`}
          className={`items-center  flex ${isRightAligned ? "flex-row-reverse" : "flex-row"}`}
        >
          <Avatar
            className={`!w-8 !h-8`}
            src={`${process.env.REACT_APP_ASSETS_BUCKET}${
              descriptions?.playerDescriptions.find(
                (p: any) => p.playerId === m.author
              )?.character_id
            }/display_picture.jpg`}
          />

          <div
            className={`space-y-1 flex flex-col ${isRightAligned ? "items-end mr-4" : "items-start ml-4"}`}
          >
            <span className="uppercase flex-grow text-white font-main mx-2">
              {m.authorName}
            </span>
            <div
              className={clsx(
                "bubble max-w-[80%] p-2",
                isRightAligned
                  ? "bubble-right !bg-fourwall-orange"
                  : "bubble-left !bg-neutral-700"
              )}
            >
              <p className="mx-3 rounded-lg font-chat text-white">{m.text}</p>
            </div>

            <time
              dateTime={m._creationTime.toString()}
              className={`text-[0.5rem] text-white mx-2 ${isRightAligned ? "text-right" : "text-left"}`}
            >
              {new Date(m._creationTime).toLocaleString()}
            </time>
          </div>
        </div>
      );
      return { node, time: m._creationTime };
    }
  );

  const lastMessageTs = messages
    .map((m: any) => m._creationTime)
    .reduce((a: number, b: number) => Math.max(a, b), 0);

  const membershipNodes: typeof messageNodes = [];
  if (conversation.kind === "active") {
    for (const [playerId, m] of conversation.doc.participants) {
      const playerName = descriptions?.playerDescriptions.find(
        (p: any) => p.playerId === playerId
      )?.name;

      let started;
      if (m.status.kind === "participating") {
        started = m.status.started;
      }
      console.log("started DEBUG", started);
      if (started) {
        membershipNodes.push({
          node: (
            <div key={`joined-${playerId}`} className="leading-tight mb-3">
              <p className="text-white text-center">
                {playerName} joined the conversation.
              </p>
            </div>
          ),
          time: started,
        });
      }
    }
  } else {
    for (const playerId of conversation.doc.participants) {
      const playerName = descriptions?.playerDescriptions.find(
        (p: any) => p.playerId === playerId
      )?.name;
      const started = conversation.doc.created;
      membershipNodes.push({
        node: (
          <div key={`joined-${playerId}`} className="leading-tight mb-3">
            <p className="text-white text-center" style={styles.joinLeave}>
              {playerName} joined the conversation.
            </p>
          </div>
        ),
        time: started,
      });
      const ended = conversation.doc.ended;
      membershipNodes.push({
        node: (
          <div key={`left-${playerId}`} className="leading-tight mb-3">
            <p className="text-white text-center" style={styles.joinLeave}>
              {playerName} left the conversation.
            </p>
          </div>
        ),
        // Always sort all "left" messages after the last message.
        // TODO: We can remove this once we want to support more than two participants per conversation.
        time: Math.max(lastMessageTs + 1, ended),
      });
    }
  }
  const nodes = [...messageNodes, ...membershipNodes];
  nodes.sort((a, b) => a.time - b.time);

  return (
    <div className="flex flex-col h-full">
      <div className="flex-grow overflow-y-auto mb-16">
        <div className="text-base sm:text-sm">
          <div className="text-black p-2">
            {nodes.length > 0 && nodes.map((n) => n.node)}
            {currentlyTyping && currentlyTyping.playerId !== humanPlayerId && (
              <div
                key="typing"
                className="space-y-1 flex flex-col items-start ml-4"
              >
                <div className="items-center flex">
                  <Avatar
                    className={`!w-8 !h-8`}
                    src={`${process.env.REACT_APP_ASSETS_BUCKET}${
                      descriptions?.playerDescriptions.find(
                        (p: any) => p.playerId === currentlyTyping?.playerId
                      )?.character_id
                    }/display_picture.jpg`}
                  />
                  <span className="uppercase flex-grow text-white font-main mx-2">
                    {currentlyTypingName}
                  </span>

                  {/* <time dateTime={currentlyTyping.since.toString()}>
                    {new Date(currentlyTyping.since).toLocaleString()}
                  </time> */}
                </div>
                <div className="bubble max-w-[80%] p-2 bubble-left !bg-neutral-700">
                  <p className="mx-3 rounded-lg font-chat text-white">
                    {/* <i>typing...</i> */}
                    <img
                      className="typing"
                      src="/images/typing.gif"
                      alt="Typing..."
                    />
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {humanPlayer &&
        inConversationWithMe &&
        conversation.kind === "active" && (
          <div className="mt-auto">
            <MessageInput
              worldId={worldId}
              engineId={engineId}
              conversation={conversation.doc}
              humanPlayer={humanPlayer}
            />
          </div>
        )}
    </div>
  );
}

const styles: { [key: string]: React.CSSProperties } = {
  joinLeave: {
    fontFamily: "var(--font_b)",
    fontStyle: "italic",
    fontSize: "0.7rem",
    textAlign: "center",
    margin: "auto",
    color: "white",
  },
};
